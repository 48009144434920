.stories-list {

  &__container {
    margin-bottom: $space__xl;
  }

  &__video {
    height: 35vh;
    padding-bottom: $space__md;

    iframe {
      width: 100%;
    }
  }

  &__content {
    color: $color__gray--3;
  }

  &__title {
    font-weight: $font__weight--bold;
    margin-bottom: $space;
  }

  &__subtitle {
    font-weight: $font__weight--bold;
    margin-bottom: $space__md;
  }

  &__description {
    margin-bottom: $space__lg;
  }

  &__quote {
    color: $color__prime;
    padding: $space__md 0;
    border-top: 1px solid $color__black;
    border-bottom: 1px solid $color__black;
    font-size: $fontsize__h5;
    font-weight: $font__weight--bold;
  }

  @include respondTo('md') {
    &__container {
      display: flex;
    }

    &__video {
      flex: 0 0 40%;
      margin-right: $space__xl;
      // padding-bottom: 56.25%;
      padding-bottom: 0;
      width: 100%;
      // height: 0;
      position: relative;

      iframe {
        height: 100%;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}