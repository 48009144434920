.fontsize--0 { font-size: $fontsize__0; }
.fontsize--1 { font-size: $fontsize__1; }
.fontsize--2 { font-size: $fontsize__2; }
.fontsize--3 { font-size: $fontsize__3; }
.fontsize--4 { font-size: $fontsize__5; }
.fontsize--5 { font-size: $fontsize__5; }
.fontsize__5 { font-size: $fontsize__5; }
.fontsize--6 { font-size: $fontsize__6; }
.fontsize--7 { font-size: $fontsize__7; }

.fontsize--h1 { font-size: $fontsize__h1; }
.fontsize--h2 { font-size: $fontsize__h2; }
.fontsize--h3 { font-size: $fontsize__h3; }
.fontsize--h4 { font-size: $fontsize__h4; }
.fontsize--h5 { font-size: $fontsize__h5; }
.fontsize--h6 { font-size: $fontsize__h6; }

.textcolor--red { color: $color__red; }
.textcolor--orange, .color-orange { color: $color__orange; }
.textcolor--lime { color: $color__lime; }
.textcolor--teal, .color-teal { color: $color__second; }
.textcolor--green, .color-green { color: $color__green; }
.textcolor--blue, .color-bule { color: $color__prime; }
.textcolor--darkblue { color: $color__prime--dk; }
.textcolor--plum { color: $color__plum; }
.textcolor--tan { color: $color__orange; }