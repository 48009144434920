// Wrapper For Tables
.table {
  display: block;
  max-width: 100%;
  overflow-x: auto;

  &__in {
    width: 100%;
  }
  
  &__th,
  &__td {
    padding: calc($space/2);
    vertical-align: middle;
    text-align: center;

    &:first-child {
      text-align: left;
      padding-left: 0;
    }
    &:last-child {
      text-align: right;
      padding-right: 0;
    }
  }
}