.stronglist {
  &__item {
    border-bottom: 1px solid $color__gray--D;
  }
  &__link {
    display: block;
    line-height: 2em;
    @include fontWeight('bold');
    @include interactiveColor($color__gray--5, $color__dark);
  }
}