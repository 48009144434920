.link {
  @include interactiveColor($color__prime, $color__prime--dk);
  
  &--block {
    display: block;
    line-height: normal;
  }

  &__list-item {
    & + & {
      margin-top: $space__paragraph;
    }
  }

  &__social {
    &--rss        { color: $color__social--rss; }
    &--facebook   { color: $color__social--facebook; }
    &--twitter    { color: $color__social--twitter; }
    &--youtube    { color: $color__social--youtube; }
    &--instagram  { color: $color__social--instagrammagenta; }
    &--googleplus { color: $color__social--googleplus; }
    &--linkedin   { color: $color__social--linkedin; }
  }

  &--lg {
    @include fontWeight('bold');
  }  

  &--lt {
    @include interactiveColor($color__white, $color__gray--9);
  }

  &--lt-2 {
    @include interactiveColor($color__white, $color__aqua);
  }

  &--dk {
    @include interactiveColor($color__dark, $color__gray--6);
  }

  &--skip {
    position: absolute;
    height: 0;
    overflow: hidden;
    z-index: 0;

    @include interactiveColor($color__second, $color__second--dk);
  }

  @include respondTo('md') {
    &--lg {
      font-size: $fontsize__5;
    }  
  }
}