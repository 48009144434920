.tabs {
  width: 100%;
  opacity: 0;
  @include transition(opacity .6s ease);

  &--show {
    opacity: 1;
  }

  &__container {
    margin-bottom: $space__component;
    display: none;
  }

  &__list {
    display: none;
  }

  &__item {
    flex: 0 0 auto;
    display: inline-block;
    margin-right: $space;
    margin-bottom: $space;
  }

  &__trigger {
    display: inline-block;
  }

  @include respondTo('md') {
    margin-bottom: -$space;

    &__select {
      display: none;
    }

    &__container {
      display: block;
    }

    &__list {
      display: block;
    }
  }
}