﻿.header {
    .CoveoSearchInterface {
        background-color: #333;
        color: #fff;
        font-family: $font__family--sans-serif;
        font-size: 18px;

        .CoveoSearchbox {
            .magic-box {
                .magic-box-suggestions {
                    font-size: $fontsize;

                    .CoveoResult.coveo-omnibox-selectable {
                        padding: 0;
                        a {
                            text-decoration: none;
                            color: $color__black;

                            padding: .25rem 1rem;
                            text-align: left;
                            transition: color .4s cubic-bezier(.165, .84, .44, 1),
                                        background-color .4s cubic-bezier(.165, .84, .44, 1);
                            background-color: $color__white;
                            line-height: 1.1rem;
                            display: table;
                            width: 100%;
                        }
                        a:visited {
                            color: $color__black;
                        }
                        a:hover,
                        a:focus {
                            background-color: $color__gray--F6;
                        }
                    }
                    .CoveoResult.coveo-omnibox-selectable.coveo-omnibox-selected {
                        a {
                            background-color: $color__gray--F6;
                        }
                    }
                }
            }
        }

        .coveo-result-section {
            margin-left: 1rem;
            font-size: $fontsize__7;
            color: $color__gray--6;
        }

        .CoveoOmniboxResultList {
            font-size: $fontsize;

            .CoveoResult.coveo-omnibox-selectable {
                padding: 0;
                a {
                    text-decoration: none;
                    color: $color__black;

                    padding: .25rem 1rem;
                    text-align: left;
                    transition: color .4s cubic-bezier(.165, .84, .44, 1),
                                background-color .4s cubic-bezier(.165, .84, .44, 1);
                    background-color: $color__white;
                    line-height: 1.1rem;
                    display: table;
                    width: 100%;
                }
                a:visited {
                    color: $color__black;
                }
                a:hover,
                a:focus {
                    background-color: $color__gray--E;
                }
            }
            .CoveoResult.coveo-omnibox-selectable.coveo-omnibox-selected {
                a {
                    background-color: $color__gray--E;
                }
            }
        }
    }

    .CoveoSearchbox {
        .CoveoSearchButton {
            border: none;
            height: 57px;
        }

        .coveo-search-button-svg {
            color: $color__prime--lt;
        }

        .magic-box {
            border: none;
            border-radius: initial;

            color: $color__white;

            .magic-box-clear {
                background: none;
                height: 57px;
                line-height: 57px;
            }

            .magic-box-input {
                border-radius: initial;
                height: 57px;
                background: none;

                .magic-box-underlay {
                    height: 100%;
                }
                
                > input {
                    font-size: 1.125rem;
                    padding: 1em;
                    padding-right: 2.5rem;
                    line-height: 1.2em;
                    font-weight: $font__weight--bold;
                    font-family: $font__family--sans-serif;
                    height: 100%;
                    color: $color__white;
                    
                    &::placeholder {
                        color: $color__white;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.coveo-sort-icon-descending--advanced {
    padding-left: 5px;
    float: right;
}
.advanced_search--button {
    position: absolute;
    z-index: 50;
    margin-top: 160px;
    font-size: 12px;
    text-align:left;
    text-transform: uppercase;
    padding: 10px;
    right: 25px;
    font-weight: bold;
    font-family: "lato";
    border: 1px solid $color__prime--lt;
}
@media only screen and (max-width: 1080px) {
    .advanced_search--button {
        display: none !important;
    }
}
.coveo-results-header{
    margin-top: 50px;
}

.form__col.component.readmore {
    width: 50%;
    @media only screen and (max-width: 992px) {
        width: 100%;
    }
}
.page__content.page__content--search, .careers-positions {

    .coveo-tab-section {
        margin-bottom: 1rem;

        .CoveoTab {
            color: $color__prime;
            font-weight: 800;

            &.coveo-selected {
                border-bottom: 2px solid $color__red;
            }
        }
    }
    
    .CoveoSort {
        &.coveo-selected {
            border-bottom: 2px solid $color__red;
            color: $color__prime;
        }
    }

    .CoveoSearchInterface {
        .coveo-main-section {
            max-width: initial;
        }

        .coveo-search-section {
            padding: 0;
            margin: 0;
            margin-bottom: 3rem;
            max-width: initial;
            width: 100%;
        }

        .coveo-facet-column {
            float: right;
            display: none;
            margin-top: 50px;
            padding: 0;
            margin-right: 0px;
            padding-left: 15px;
        }
        @media only screen and (max-width: 1080px) {
            .coveo-facet-column {
                display: block;
            }
        }
        
        .coveo-results-column {
            padding: 0;
        }

        .CoveoResultList {
            .coveo-highlight {
                color: $color__prime;
            }
        }
    }
    
    .CoveoSearchbox {
        .CoveoSearchButton {
            border: none;
            border-radius: 0;
        }
        .magic-box {
            background-color: $color__gray--D;
            color: $color__gray--5;
            border: none;
            border-radius: 0;
            
            .magic-box-clear {
                background-color: $color__gray--D;
            }
            
            .magic-box-input {
                border-radius: 0;
                
                > input {
                    font-size: 1.125rem;
                    background-color: $color__gray--D;
                    min-height: 1.2em;
                    padding: .8em;
                    line-height: 1.2em;
                    border-radius: 0;
                    color: $color__gray--5;
                    transition: $transition__interactive;
                    font-weight: $font__weight--bold;
                    text-indent: 0;
                    font-family: $font__family--sans-serif;
                }
            }

            .magic-box-clear-svg {
                transition: $transition__interactive;

                &:hover {
                    color: $color__red--dk;
                }
            }
            
            .magic-box-suggestions {
                font-size: $fontsize;
                
                .CoveoResult.coveo-omnibox-selectable {
                    padding: 0;
                    a {
                        text-decoration: none;
                        color: $color__black;

                        padding: .25rem 1rem;
                        text-align: left;
                        transition: color .4s cubic-bezier(.165, .84, .44, 1),
                                    background-color .4s cubic-bezier(.165, .84, .44, 1);
                        background-color: $color__white;
                        line-height: 1.1rem;
                        display: table;
                        width: 100%;
                    }
                    a:visited {
                        color: $color__black;
                    }
                    a:hover,
                    a:focus {
                        background-color: $color__gray--F6;
                    }
                }
                .CoveoResult.coveo-omnibox-selectable.coveo-omnibox-selected {
                    a {
                        background-color: $color__gray--F6;
                    }
                }
            }
        }
    }
    
    .CoveoQuerySummary,
    .CoveoDidYouMean,
    .coveo-facet-breadcrumb-title {
        color: $color__gray--5;
        font-family: $font__family--sans-serif;
    }

    .coveo-breadcrumb-clear-all {
        background-color: $color__white;
        border-color: $color__black;
        border-radius: 0;
        font-size: $fontsize__6;
        padding: 0.25em 0.5em;
        color: $color__black;
        transition: $transition__interactive;
        line-height: 1.2em;
        text-transform: uppercase;
        margin: 5px 0;

        .coveo-breadcrumb-clear-all-icon {
            .coveo-exclusion-svg {
                fill: $color__black;
            }
        }
        
        &:hover {
            background-color: $color__prime;
            border-color: $color__prime;
            color: $color__white;
            
            .coveo-breadcrumb-clear-all-icon {
                .coveo-exclusion-svg {
                    fill: $color__red;
                }
            }
        }
    }
    
    .coveo-facet-header {
        background-color: $color__second--lt;
        border-color: $color__second--lt;
        color: $color__black;
        text-transform: uppercase;
        font-size: 1.125rem;
        line-height: 1.2em;
        border-radius: 0;
        font-family: $font__family--sans-serif;
        
        .coveo-facet-header-title {
            color: $color__black;
            font-size: 1.125rem;
            font-weight: $font__weight--normal;
        }
        
        .coveo-facet-header-wait-animation {
            .coveo-facet-header-wait-animation-svg {
                color: $color__black;
            }
        }

        .coveo-facet-header-eraser,
        .coveo-facet-settings-more-svg {
            color: $color__black;
        }
    }
    
    .coveo-facet-value.coveo-facet-search-button {
        color: $color__prime;
    }
    
    .coveo-facet-breadcrumb-value {
        color: $color__prime;
        
        .coveo-exclusion-svg {
            full: $color__prime;
        }

        &:hover {
            color: $color__prime--dk;

            .coveo-exclusion-svg {
                color: $color__prime--dk;
            }
        }
    }
    
    .CoveoFacet {
        border: 0;
        border-radius: 0;

        .coveo-facet-header-settings-section {
            .coveo-facet-header-eraser-svg {
                transition: $transition__interactive;
            }

            &:hover {
                .coveo-facet-header-eraser-svg {
                    color: $color__red;
                }
            }
        }
    }
    
    .CoveoResult {
        a {
            padding: 1rem;
            color: $color__black;
            
            &:hover {
                color: $color__gray--5;
                text-decoration: none;
            }
            
            &:visited {
                color: $color__black;
            }
        }
        .CoveoResultLink:visited,
        .CoveoResultLink:visited:hover,
        a.CoveoResultLink:visited,
        a.CoveoResultLink:visited:hover
        {
            color: $color__gray--5;
        }
    }

    .coveo-result-list-container .coveo-list-layout+.coveo-list-layout:nth-child(2n) {
        transition: background-color .6s ease, border-color .6s ease;
        background-color: $color__bg--md;

        &:hover {
            background-color: $color__bg;
        }
    }

    .coveo-list-layout.CoveoResult {
        padding: 0;
        border-bottom: none;
    }

    .coveo-result-frame {
        .professional__container {
            display: flex;
            flex-direction: column;

            .professional__row--horizontal {
                display: flex;
                flex-direction: row;
                padding: 1rem;
                color: $color__black;
                transition: $transition__interactive;
                text-decoration: none;
                
                &:hover {
                    color: $color__gray--5;
                }

                .professional__column--left {
                    flex: 0 0 auto;
                }

                .professional__column--right {
                    flex: 1 1 auto;
                    padding: 1rem;
                }

                .professional__column--full {
                    width: 100%;
                }

                .person__media {
                    height: auto;
                    max-height: 9rem;
                    max-width: 13.125rem;
                }

                .person__image {
                    width: 100%;
                    display:block;
                }

                .person__meta {
                    .person__phone {
                        color: inherit;
                        &-listing::before {
                            content:"+ "
                        }
                    }
                }

                @include respondTo('sm') {
                    .person__left {
                        width: 200px;
                    }
                }

                @media screen and (max-width: 575px) {
                    flex-direction: column;
                }
            }
        }
    }

    .coveo-pager-list-item {
        border-radius: 0;
        margin: 5px 6px;
        padding: 4px 8px;
        color: $color__black;
        transition: $transition__interactive;

        .coveo-pager-list-item-text {
            color: $color__black;
        }

        &:not(.coveo-pager-anchor) {
            border: thin solid $color__black;
        }

        &.coveo-active {
            border: thin solid $color__prime;
            color: $color__white;
            background-color: $color__prime;

            a {
                color: $color__white;
            }
        }

        &:hover {
            &:not(.coveo-pager-anchor) {
                color: $color__white;
                background-color: $color__prime;
                border: thin solid $color__prime;
            }

            a {
                color: $color__white;
            }
        }

        .coveo-pager-next-icon-svg,
        .coveo-pager-previous-icon-svg {
            color: $color__prime;
            width: 11px;
            height:20px;
            vertical-align: middle;
        }
    }


    .coveo-facet-breadcrumb-value {
        padding: 0.25em 0.5em;
        border-radius: 0;
        border: thin solid $color__prime;
        line-height: 1.2em;
        transition: $transition__interactive;
        color: $color__prime;
        vertical-align: middle;
        margin: 0.3em;

        &.coveo-selected * {
            color: $color__prime;
            transition: $transition__interactive;
        }

        .coveo-facet-breadcrumb-clear {
            color: $color__red;
        }

        &:hover {
            color: $color__white;
            text-decoration: none;
            background-color: $color__prime;

            &.coveo-selected * {
                color: $color__white;
            }

            .coveo-facet-breadcrumb-clear {
                .coveo-exclusion-svg {
                    fill: $color__red;
                }
            }
        }
    }

    .CoveoBreadcrumb {
        .coveo-breadcrumb-items {
            padding-bottom: 0;
        }
    }
}

.page__content:not(.page__content--search) {

    .CoveoFacet {
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        background: inherit;
        position: static;

        background-color: $color__gray--6;
        color: $color__white;
        height: 29px;
    }

    .coveo-facet-header,
    .coveo-facet-values,
    .coveo-facet-footer {
        display: none;
    }

    .coveo-facet-search {
        display: block;

        border: none;
        border-radius: 0;
        background: inherit;

        margin: 0;
        padding: 0;
    }

    .coveo-facet-search-middle {
        height: 29px;
    }

    .coveo-facet-empty {
        display: block;
    }

    .coveo-facet-placeholder {
        display: none !important;
    }

    .coveo-facet-value-count {
        display: none;
    }

    .coveo-facet-value-option {
        font-family: $font__family--sans-serif;
    }

    .CoveoSearchInterface {
        background-color: inherit;
        font-family: inherit;
        font-size: inherit;
        color: inherit;

        .coveo-main-section {
            max-width: initial;
        }

        .coveo-search-section {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        input[type='text'] {
            font-size: 1rem;
            height: 100%;
            padding: .25em 1em;
            line-height: 1.2em;
            color: $color__white;
            background-color: $color__gray--6;
            transition: $transition__interactive;
            font-weight: $font__weight--bold;
            font-family: $font__family--sans-serif;
        }
    }

    .CoveoSearchbox {
        .CoveoSearchButton {
            display: none;
        }
        .magic-box {
            background-color: $color__gray--D;
            color: $color__gray--5;
            border: none;
            border-radius: 0;
    
            .magic-box-clear {
                background-color: $color__gray--6;
                height: 29px;
                line-height: 2.1em;
                font-size: 1rem;

                .magic-box-icon {
                    &:before {
                        content: none;
                    }
                }
            }
    
            .magic-box-input {
                border-radius: 0;
                height: 29px;
    
                > input {
                    font-size: 1rem;
                    background-color: $color__gray--6;
                    height: 100%;
                    padding: .25em 1em;
                    line-height: 1.2em;
                    border-radius: 0;
                    color: $color__white;
                    transition: $transition__interactive;
                    font-weight: $font__weight--bold;
                    text-indent: 0;
                    font-family: $font__family--sans-serif;

                    &::placeholder {
                        color: $color__white;
                    }
                }

                .magic-box-underlay {
                    display: none;
                }
            }

            .magic-box-clear-svg {
                transition: $transition__interactive;
                color: $color__white;

                &:hover {
                    color: $color__red;
                }
            }
        }
    }

    .coveo-facet-breadcrumb-title {
        color: $color__white;
    }

    .coveo-facet-search-magnifier-svg,
    .coveo-facet-search-wait-animation-svg {
        width: 16px;
        height: 16px;
        color: $color__white;
    }

    .coveo-facet-search-input {
        font-size: 1rem;
        padding: .25em 1em;
        line-height: 1.2rem;
        color: $color__white;
        font-weight: 700;
        font-family: $font__family--sans-serif;
        background-color: $color__gray--6;

        &::placeholder {
            color: $color__white;
        }
    }

    .magic-box {
        .magic-box-suggestions {
            font-size: $fontsize;
            
            .CoveoResult.coveo-omnibox-selectable {
                padding: 0;
                a {
                    text-decoration: none;
                    color: $color__black;

                    padding: .25rem 1rem;
                    text-align: left;
                    transition: color .4s cubic-bezier(.165, .84, .44, 1),
                                background-color .4s cubic-bezier(.165, .84, .44, 1);
                    background-color: $color__white;
                    line-height: 1.1rem;
                    display: table;
                    width: 100%;
                }
                a:visited {
                    color: $color__black;
                }
                a:hover,
                a:focus {
                    background-color: $color__gray--F6;
                }
            }
            .CoveoResult.coveo-omnibox-selectable.coveo-omnibox-selected {
                a {
                    background-color: $color__gray--F6;
                }
            }
        }
    }

    .highlight {
        font-weight: $font__weight--normal;
    }

    .CoveoBreadcrumb {
        padding: 0;
        border: none;
        margin-bottom: 1rem;

        .coveo-breadcrumb-items {
            padding: 0;
            margin: 0;            
        }


        .coveo-facet-breadcrumb-value {
            padding: 0.25em 0.5em;
            border-radius: 0;
            border: thin solid $color__white;
            line-height: 1.2em;
            margin-right: 0.7em;
            margin-bottom: 0.7em;
            margin-left: 0;
            
            @include interactiveColor($color__white, $color__gray--D);

            &.coveo-selected * {
                @include interactiveColorTwo($color__white, $color__gray--D);
            }

            .coveo-exclusion-svg {
                fill: $color__white;
            }

            &:hover {
                text-decoration: none;
                border: thin solid $color__gray--D;

                .coveo-exclusion-svg {
                    fill: $color__gray--D;
                }

                .coveo-facet-breadcrumb-clear {
                    .coveo-exclusion-svg {
                        fill: $color__gray--D;
                    }
                }
            }
        }

        .coveo-breadcrumb-clear-all {
            border: thin solid $color__white;
            @include interactiveColor($color__white, $color__gray--D);

            .coveo-exclusion-svg {
                fill: $color__white;
            }

            &:hover {
                border: thin solid $color__gray--D;

                .coveo-exclusion-svg {
                    fill: $color__gray--D;
                }
            }
        }
    }

    .button__advanced {
        float: left;
        margin-left: 0;
    }

    .form__subheader {
        color: $color__white;
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
    /*
    .section {
        overflow: unset;
    }
    */
}

.page__content .CoveoLogo {
    display: none !important;
}

@include respond-to-print {
    .page__content--search {
        .coveo-ui__container {
            display: none;
        }
    }

    .coveo-main-section {        
        .coveo-facet-column,
        .coveo-search-section {
            display: none !important;
        }

        .coveo-results-column {
            .CoveoPager {
                display: none !important;
            }
            .coveo-result-list-container {
                .articleblock__inner {
                    padding-left: 0;
                }
            }            
        }

        .coveo-results-header {
            box-shadow: none;
        }
    }
}