.datalist {
  display: block;
  max-width: 100%;

  &__in {
    width: 100%;

    & + & {
      margin-top: $space__component;
    }
  }

  &__title {
    font-size: $fontsize__5;
    margin-bottom: $space__sm;
  }

  &__dd,
  &__dt {
    display: inline-block;
    width: 50%;
    padding: calc($space/2);
    vertical-align: middle;
    text-align: left;
  }
  &__dt {
    padding-left: 0;
  }
  &__dd {
    padding-right: 0;
  }
}