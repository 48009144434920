.readmore {
    &__block {
        @include transition(max-height .6s ease, height .6s ease, margin .6s ease);

        & + &:not(.readmore__block--hidden) {
            margin-top: $space__paragraph;
        }

        &--responder {
            overflow: hidden;
            max-height: 100%;

            &:not(.readmore--active) {
                max-height: 0;
            }
        }
    }

    @include respond-to-print {
        &__block {
            &--responder {
                height: 100% !important;

                &:not(.readmore--active) {
                    max-height: none;
                }
            }
        }
    }
}
