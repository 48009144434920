.searchcollapse {
  position: relative;
  top: 0;
  left: 0;
  z-index: $z__header + 1;
  width: 0%;
  @include transition(transform .6s ease, left .6s ease, width .6s ease);
  
  .CoveoSearchInterface {
      min-width: initial;
      width: 100%;
      opacity: 0;
      visibility: hidden;

      @include transition(opacity .6s ease-in);
  }

  &--open {
      width: 100%;

      .CoveoSearchInterface {
          visibility: visible;
          opacity: 100;
      }
  }
}