
@mixin arrow($direction: up,  $size: 20px, $sizew: 20px, $color: '') {
  display: inline-block;
  content: "";

  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: $size;
  border-color: transparent;

  @if $direction == "up" {
    border-top: none;
    border-left-width: $sizew;
    border-right-width: $sizew;
    border-bottom-color: $color;
  } @else if $direction == "down" {
    border-bottom: none;
    border-left-width: $sizew;
    border-right-width: $sizew;
    border-top-color: $color;
  }  @else if $direction == "left" {
    border-left: none;
    border-left-width: $sizew;
    border-right-width: $sizew;
    border-right-color: $color;
  } @else if $direction == "right" {
    border-right: none;
    border-left-width: $sizew;
    border-right-width: $sizew;
    border-left-color: $color;
  }
}