.menu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  position: absolute;
  top: $size__header--mobile;
  left: 0;
  z-index: $z__top;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 100%;
  padding: $space__md $space;
  background-color: $color__bg--dk-2;
  overflow: auto;
  @include transition(height .8s ease, opacity .8s ease, visibility .8s ease);

  &--active {
    opacity: 1;
    visibility: visible;
    height: calc(100vh - #{$size__header--mobile});
  }

  @include respondTo('md') {
    display: block;
    position: relative;
    top: auto;
    opacity: 1;
    visibility: visible;
    height: auto;
    background-color: transparent;
    padding: 0;
    overflow: visible;
    @include transition(none);

    &__toggle {
      display: none;
    }
  }
}