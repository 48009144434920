.careers-cta {
  z-index: 5;
  position: relative;
  min-height: rem(574px);

  &--desktop {
    display: none;
  }

  &__background {
    background-repeat: no-repeat;
    // background-position: right;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    // min-height: 48vh;
    height: 100%;
    z-index: 3;
    background-size: contain;
  }

  &__gradient {
    background: linear-gradient(180deg, #004C97 51.29%, rgba(0, 76, 151, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }

  &__content {
    position: relative;
    padding: $space__md $space;
    z-index: 5;


  }

  &__title {
    color: $color__white;
    font-size: $fontsize__h2;
    font-weight: $font__weight--bold;
    padding-bottom: $space__md;

    &--blue {
      color: $color__aqua;
    }
  }

  &__view-all {
    color: $color__white;
    font-size: $fontsize__h3;
    font-weight: $font__weight--bold;

    &:hover {
      color: $color__aqua;
    }
  }

  @include respondTo('md') {
    min-height: rem(320px);
    display: none;

    &--desktop {
      display: block;
    }

    &__background {
      width: 55%;
      left: inherit;
      right: 0;
      top: 0;
      background-size: cover;
    }

    &__gradient {
      background: linear-gradient(90deg, #004C97 51.29%, rgba(0, 76, 151, 0) 100%);
    }

    &__title {
      font-size: $fontsize__careers-desktop;
      padding-bottom: $space__md;
    }

    &__content {
      padding: $space__lg $space__xxl;
      width: 55%;
    }

  }
}