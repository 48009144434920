.articleblock {
  position: relative;
  @include transition(background-color .6s ease, border-color .6s ease); 

  .articleblock__list & + & {
    background-color: $color__white;

    &:hover {
      background-color: $color__gray--F6;
    }
  }

  .articleblock__list--strong & {
    background-color: $color__gray--F6;
    border-bottom: 1px solid $color__gray--D;

    &:hover {
      background-color: $color__bg;
    }
  }


  &--featured {
    background-color: $color__gray--F6;
    &:hover {
      background-color: $color__bg;
    }
  }
  .articleblock__list & + &:nth-child(even) {
    background-color: $color__bg--md;

    &:hover {
      background-color: $color__bg;
    }

  }
  .articleblock__featurelist & {
    background-color: $color__bg;

    &:nth-child(even) {
      background-color: $color__bg--md;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    text-decoration: inherit;

    &#{&} {
      @include interactiveColor($color__dark, $color__gray--5);
    }
    
    .articleblock--featured &,
    .articleblock__featurelist & {
      padding: $space__md;
      min-height: rem(350px);

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        height: rem(350px);
      }

      &--img {
        padding-bottom: $size__articleimg--mobile;
      }
    }  

    .articleblock__list &,
    .articleblock__list--strong & {
      padding: $space;
      display: block;
      min-height: auto;
    }

    &--cols {
      display: block;
    }

    &--img {
      padding-bottom: $size__articleimg--mobile;
    }

    &#{&} {
      &:hover {
        .articleblock__row--img {
          transform: scale(1.2);
        }
      }
    }
  }

  &__inner {
    &--img {
      padding-bottom: $size__articleimg--mobile;
    }
  }

  &__col {
    display: block;
    width: 100%;

    &--img {
      width: 100%;
      height: auto;
      margin: $space__md 0 0;
      overflow: hidden;
      @include backgroundFull();
      background-size: contain;
    }
  }
  
  &__row {
    flex: 0 0 auto;
    position: relative;
    width: 100%;

    &--withright {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    & + & {
      margin-top: $space__paragraph;
      
      &--short {
        margin-top: $space__ty;
      }

      &--tall {
        margin-top: $space;
      }
    }

    &--img {
      position: absolute;
      top: auto;
      left: 0;
      bottom: 0;
      margin: 0 !important;
      overflow: hidden;
      height: $size__articleimg--mobile;
      @include transition(transform .3s ease);
      @include backgroundFull(center, center);
      background-size: contain;
    }
  }

  &__title {
    font-size: $fontsize__4;
    @include fontWeight('bold');
    line-height: 1.25em;
    margin-bottom: $space__sm;

    &--lg {
      font-size: $fontsize__3--lg;
    }
    &--sm {
      font-size: $fontsize;
    }
  }

  &__subtitle {
    font-size: $fontsize__5;
    @include fontWeight('bold');
    line-height: 1.25em;
    color: $color__gray--5;

    &--spaced {
        margin-bottom: $space__sm;
    }
  }

  &__details {
    font-size: $fontsize__6;
    color: $color__gray--5;
    line-height: 1.4em;
  }
  
  &__link {
    font-size: $fontsize__5;
    font-weight: 700;
  }

  &__meta {
    display: inline-block;
    font-size: $fontsize__6;
    @include fontWeight('bold');
    text-transform: uppercase;
    color: $color__tan;

    /*
    &:after {
      display: inline-block;
      content: "|";
      margin-left: $space__sm;
      margin-right: $space__sm;
    }
    */

    &:last-child {
      &:after {
        content: "";
        content: none;
      }
    }

    & + .articleblock__meta {
      &:before {
        display: inline-block;
        content: "|";
        margin-left: .333em;
        margin-right: $space__sm;
      }
    }
  }

  &__time {
    margin-left: $space;
    line-height: $fontsize__4;
  }

  &__image-wrapper {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    height: $size__articleimg--mobile;
  }

  &__image {
    position: relative;
    
    .articleblock__row--img & {
      display: none;
    }
    .articleblock__col--img & {
      width: auto;
    }
  }

  &__video {
    width: 100%;
    /*min-height: calc(#{rem(350px)} - (#{$space__md} * 2));;*/

    &--tall {
      height: $size__tile--sm;
    }
  }

  @include respondTo('sm') {

    &__inner {
      // display: flex;
      // flex-flow: row wrap;
      // justify-content: flex-start;
      // align-items: flex-start;
      width: 100%;
      min-height: 100%;
  
      &--cols {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;
        min-height: 0;
      }

      .articleblock__col--img {      
        min-height: calc(#{rem(350px)} - (#{$space__md} * 2));
      }  
    }

    &__col {
      flex: 1 0;
      width: calc(50% - #{$space__md});
      margin-right: $space__md;
  
      .articleblock__featurelist .articleblock:nth-child(even) & {
        &:first-child {
          order: 2;
          margin-right: 0;
          margin-left: $space__md;
        }
        &:last-child {
          order: 1;
        }
      }
  
      &--img {
        flex: 0 0 auto;
        width: 45%;
        height: auto;
        min-height: 100%;
        margin: 0;
      }
    }

    &__row {
      & + & {
        &--tall {
          margin-top: $space__lg;
        }
      }
    }

    &__image {
      .articleblock__row--img {
        opacity: 0;
      }
      .articleblock__col--img & {
        display: none;
      }
    }

    &__video {
      width: auto;
      height: auto;
    }
  }

  @include respondTo('md') {
    &__inner {
      &--img {
        padding-bottom: $size__articleimg;
      }
    } 
    &__row {
      &--img {
        height: $size__articleimg;
      }
    }
    &__image-wrapper {
      height: $size__articleimg;
    }

    &__featurelist--careers {
      padding: 0 $space__xl;

      
      .articleblock.articleblock.articleblock.articleblock {
        background-color: $color__white;
        
        .articleblock__inner {
          min-height: rem(500px);
        }

        .articleblock__col {
          width: 35%;   
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: $space;
          margin-bottom: $space;
        }

        .articleblock__col--img {
          width: 55%;
          background-size: cover;
        }


        .articleblock__row--tall {
          font-size: $fontsize__careers-desktop;
          margin-top: 0;
        }

        .articleblock__row--tall,
        .articleblock__row--short,
        .articleblock__details {
          color: $color__gray--3;
        }

        .articleblock__video {
          width: 55%;
          height: auto;
        }
      }
    }
  }

  @include respondTo('xl') {
    &__inner {
      &--img {
        padding-bottom: $size__articleimg--xl;
      }
    } 
    &__row {
      &--img {
        height: $size__articleimg--xl;
      }
    }
    &__image-wrapper {
      height: $size__articleimg--xl;
    }
  }

  &__row {
    &--img {
      @include no-print;
    }
  }

  &__col {
    &--img {
      @include no-print;
    }
  }

  @include respond-to-print {

    &__inner {
      .articleblock--featured &,
      .articleblock__featurelist & {
        min-height: 0;
        padding-left: 0;
        padding-right: 0;
      }

      &--img {
        padding-bottom: 0;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &__featurelist--careers {
      .articleblock.articleblock.articleblock.articleblock {
        .articleblock__video {
          height: 100%;
        }
      }
    }
  }
}