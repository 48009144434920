.authors {
  display: block;
  margin-bottom: $space__paragraph;

  &__item {
    display: inline-block;
    margin-right: $space__sm;
    color: $color__tan;
    font-size: $fontsize__5;
    font-weight: bold;
    line-height: 1.3em;

    &:not(:first-child) {
      &:not(:last-child) {
        &:after {
          display: inline;
          content: ',';
        }
      }
    }
  }
}