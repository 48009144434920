.richtext {

  .eventlist li,
  .BioExpItem {
    list-style: none;
    margin-left: 0;
    @extend .articleblock;
    @extend .articleblock__inner;

    padding: $space;

    > a {
      text-decoration: none;
      max-width: 100%;
    }

    &:before {
      display: none;
    }

    background-color: $color__bg;

    &:hover {
      background-color: $color__bg--md;
    }

    &:nth-child(even) {
      background-color: $color__bg--md;
  
      &:hover {
        background-color: $color__bg;
      }
    }
    .BioExpAbstract,
    .BioExpHeadline {
        max-width: 100%;
    }
  }

  .eventlist a,
  .BioExpHeadline {
    @include interactiveColor($color__dark, $color__gray--5);
    @extend .articleblock__title;
  }

  .eventlist .locationGray,
  .eventlist li .bold {
    @extend .articleblock__meta;
    &:after {
      display: none;
    }
  }
}