.profile {
  position: relative;
  display: block;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: inherit;
  min-height: inherit;
  background-color: $color__prime;

  &__col {
    position: relative;
    display: block;
    padding: $space__section calc($space__section / 2);
    flex: 0 1;
    width: 100%;
    height: auto;
    
    &--media {
      overflow: hidden;
      padding: 0;
      height: 55%;
      max-height: $size__tile;
      background-color: rgba($color__dark, 0.25);

      &--hidden {
          display: none;
      }
    }
  }

  &__media {
    position: relative;
    z-index: $z__behind;
    overflow: hidden;
  }

  &__image {
    position: relative;
    // left: 50%;
    width: inherit;
    // transform: translateX(-50%);
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  &__row {
    &--nobr {
      white-space: nowrap;
    }
    & + & {
      margin-top: $space;
    }

    &--short + & {
      margin-top: 0;
    }
    
    &--tall + & {
      margin-top: $space__md;
    }

    &--meta {
      display: none;
    }
  }

  &__name {
    display: inline-block;
    margin-right: .333em;
    color: $color__white;
    font-size: $fontsize__h3;
    @include fontWeight('bold');
    line-height: 1.08em;
    text-transform: uppercase;

    &--em {
      color: $color__aqua;
    }
  }

  &__title {
    color: $color__aqua;
    font-size: $fontsize__3;
    @include fontWeight('light');
  }

  &__metatitle {
    color: $color__white;
    margin-right: $space__sm;
    @include fontWeight('bold');
    text-transform: uppercase;
    font-size: $fontsize;
    display: inline-block;
  }
  &__metalabel {
    color: $color__white;
    font-size: $fontsize__6;
    margin-right: $space__sm;
    text-transform: uppercase;
  }

  &__meta {
    display: block;
    margin-bottom: $space;
    color: $color__white;
    font-size: $fontsize__6;
    @include fontWeight('light');

    &--link {
      @include interactiveColor($color__white, $color__aqua);
    }

    &--extra-spacing {
      margin-top: 5px;
    }
    
  }

  &__tools {
    display: inline-block;
  }

  &__tool {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-left: $space__sm;

    &--nodesktop {
      display: inline-block;
    }
  }

  @include respondTo('sm') {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &__col {
      flex: 1 1 auto;
      position: relative;
      padding: $space__section;
      width: 50%;
      height: auto;

      &--media {
        flex: 0 0 auto;
        overflow: hidden;
        padding: 0;
        height: inherit;
        max-height: none;
      }
    }
  
    &__media {
      position: relative;
      z-index: $z__behind;
      overflow: hidden;
      width: auto;
      max-width: none;
      height: 100%;
    }
  
    &__image {
      width: auto;
      max-width: none;
      height: 100%;
      max-height: 100%;
    }

    &__image {
      position: absolute;
      left: 50%;
      top: 0;
      @include translateX(-50%);
    }

    &__meta {
      display: inline-block;
      margin-right: $space__sm;
      margin-bottom: 0;
    }
  }

  @include respondTo('md') {
    &__col {
      &--media {
        width: 400px;
      }
    }

    &__name {
      font-size: $fontsize__h2;
      display: block;
      margin-right: 0;
    }
  
    &__title {
      font-size: $fontsize__3--lg;
    }
  
    &__meta {
      font-size: $fontsize;
    }

    &__row {
      &--meta {
        display: block;
      }
    }

    &__tool {
      &--nodesktop {
        display: none;
      }
    }
  }

  @include respondTo('xl') {
    &__col {
      &--media {
        width: 586px;
      }
    }
  }

  @include respond-to-print {
      &__col {
          padding: 0;
      }
  }
}