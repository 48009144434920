.sidebar {
  height: inherit;

  &__inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: inherit;
    padding: 0 0 0 $space__sm;

    > * {
      flex: 0 0 auto;
    }
  }
  

  &__mobilemenu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    height: 100%;
  }


  @include respondTo('md') {
    // .page__aside--alt & {
    //   border-right: thin solid $color__gray--9;
    // }
  }
  
  @include respondTo('md') {
    display: block;
    height: auto;
    margin-top: 1rem;

    padding-top: calc($space__pagetop/2);
    padding-left: $space__sidebar;
    padding-right: $space__sidebar;
    padding-bottom: $space__sidebar;

    &__inner {
      display: block;
      height: auto;
    }
  }

  @include respondTo('lg') {
    padding-top: 2rem;
  }

  &__mobilemenu {
    @include no-print;
  }
}