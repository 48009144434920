// --------------------------------------------------
//Site Standards
// --------------------------------------------------
*,
*:before,
*:after {
  box-sizing: border-box;
}

// Body reset
html,
body {
  height: 100%;
  font-size: $fontsize;
  line-height: 1.1;
}

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  position: relative;
  color: $color__text;
  @include fontFamily('sans-serif');
  @include fontWeight('normal');
  background-color: $color__bg;
  text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html[lang="ja"],
html[lang="zh"] {
    body {
        @include fontFamilyImportant('legacy');
    }
}

#__nuxt,
#__layout {
  height: 100%;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  @include interactive;
  @include interactiveColor($color__prime, $color__prime--dk);
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
}

a,
input,
button,
select,
textarea {
  &:focus {
    outline: $rule__outline-style;
    outline-offset: $rule__outline-offset;
    @include transition(outline .3s ease);
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

// iOS "clickable elements" fix for role="button"
[role="button"] {
  cursor: pointer;
}

button {
  border: none;
  background: none;
}

.clearfix {
  @include clearfix();
}

i, cite, address {
    font-style: italic;
}

h2 {
  font-size: $fontsize__3;
}

.no-print {
  @include no-print;
}

@include respond-to-print() {
  *  {
    background: none !important;
    color: $color__black !important;
  }
  body {
    color: $color__black;
  }
}