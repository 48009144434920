.linked-list {
  
  &__item {
    border-top: 1px solid $color__gray--6;
    font-size: $fontsize;
    color: $color__gray--6;
    display: block;
    padding: $space__sm;
    font-weight: $font__weight--bold;

    &:last-child {
      border-bottom: 1px solid $color__gray--6;
    }

    &:hover {
      color: $color__black;
    }
  }
  
    &__cta {
    border-top: 1px solid $color__gray--6;
    font-size: $fontsize;
    display: block;
    padding: $space__sm;
    font-weight: $font__weight--bold;
    color: $color__prime;
    }

    &__button {
      border: 1px solid #fff;
      font-size: 1.125rem;
      margin-right: .5rem;
      padding: .25rem 1rem;
      background-color: $color__prime;
      color: #fff;
      display: block;
      width: max-content;

      &:hover {
        color: $color__prime;
        background-color: $color__second;
      }
    }

  // this fixes the stacked lines when the two columns merge in mobile
  &:not(:first-of-type) {
    .linked-list__item {

      &:first-child {
        border-top: 0;
      }
    }
  }

  @include respondTo('md') {
    
    &__two-column {
      display: flex;

      .linked-list {
        width: 100%;
        
        &:first-of-type {
          padding-right: $space;
        }
      }
    }

    &:not(:first-of-type),
    &:not(:first-child) {
      .linked-list__item {
        &:last-child {
          border-top: 1px solid $color__gray--6;
        }
      }
    }

    &__item.linked-list__item.linked-list__item {
      &:first-child {
        border-top: 0;
      }
    }
  }

}