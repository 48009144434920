.button {
  &,
  &--base {
    display: inline-block;
    @include input();
    @include interactive();
    text-transform: uppercase;

    @include no-print();
  }

  border: 1px solid;
  border-radius: 3px;
  @include inputSize($fontsize__btn);
  @include inputColor($color__white, $color__prime--dk, $color__prime--dk);

  &__bar & + & {
    margin-left: $space;
  }

  &--secondary {
    @include inputColor($color__white, $color__second, $color__second);
  }

  &--secondary {
    @include inputColor($color__white, $color__second, $color__second);
  }

  &--inverse {
    @include inputColor($color__prime--dk, $color__white, $color__prime--dk);
  }

  &--dark {
    @include inputColor($color__white, $color__gray--3, $color__gray--3, $color__second);
  }

  &--padded {
      margin-bottom:2rem;
  }

  &--lt {
    /*@include inputColor($color__gray--3, $color__white, $color__dark, $color__white, $color__prime, $color__prime);*/
    @include inputColor($color__gray--3, $color__white, $color__dark, $color__white, $color__prime--dk, $color__prime--dk);
  }

  &--lt-2 {
    @include inputColor($color__gray--3, $color__white, $color__white, $color__prime--dk, $color__aqua, $color__aqua);
  }

  &--green {
    @include inputColor($color__white, $color__green--dk, $color__green--dk, $color__white, $color__green, $color__green);
  }

  &--sm {
    @include inputSize($fontsize__btn--sm, 1em, .25em, .5em);
  }

  &--lg {
    @include inputSize($fontsize__btn--lg, 1em,  $space * 1.5, $space * 3);
  }

  &--full {
    display: block;
    width: 100%;
  }

  &--tool {
    @extend .button--base;
    @extend .button--full;
    @include inputSize($fontsize, 1em,  $space__sm, $space);
    padding-left: $space;
    @include fontWeight('bold');
    @include inputColor($color__white, transparent, transparent, $color__dark);
    text-align: left;
  }

  &--alpha {
    border: none;
    @include inputSize($fontsize, 1em, $space__sm, .5em);
    @include inputColor($color__white, transparent, transparent, $color__aqua, transparent, transparent);
    font-weight: bold;

    @include respondTo('xxl') {
      font-size: 28px;
    }

    &--disabled,
    &:disabled {
      $color__disabled: darken($color__white, 30%); 
      color: $color__disabled;
      background: none;

      &:hover {
        color: $color__disabled;
        background: none;
      }
    }
  }

  &__advanced {
    position: relative;
    @include interactiveColor($color__white, $color__gray--D);
    padding-right: $space;

    &:after {
      font-size: .5em;
      margin-left: .5em;
      position: absolute;
      right: 0;
      top: 50%;
      @include translateY(-50%);
    }

    &--inactive {
      &:after {
        @include icon($icon-plus);
      }
    }
 
     &--active {
      &:after {
        @include icon($icon-minus);
      }
    }
  }
  &--videotoggle {
    font-size: $fontsize__2;
    @include interactiveColor($color__white, $color__aqua);
    text-shadow: 2px 2px rgba($color__black, 0.3);
    z-index: 1;

    &-active {
      @include interactiveColor($color__white, $color__aqua);
    }
  }

  &--menu {
    display: block;
    height: 100%;
    width: $size__header--mobile;
    padding: 0;
    font-size: $fontsize__3--lg;

    @include inputColor($color__gray--F, $color__black, $color__black);
    
    &-active {
      font-size: $fontsize__4;

      @include inputColor($color__white, $color__prime, $color__prime);
    }
  }

  &__tab {
    @include inputColor($color__black, $color__white, $color__gray--3, $color__black, $color__second, $color__second);
  }

  &__bar {
    margin-top: $space__component;

    &--textright {
      text-align: right;
      > * {
        margin-left: $space;
      }
    }

    &--left {
      text-align: left;
    }
    &--right {
      text-align: right;
    }
    &--center {
      text-align: center;
    }

    &--slim {
        margin-top: 0;
    }

    @include no-print;
  }
}