.section {
  position: relative;
  background-color: transparent;
  @include transition(height 1000ms ease, margin 1000ms ease, padding 1000ms ease);
  
  &__container {
    &:nth-of-type(odd) {

    }

    &:nth-of-type(even) {
      .section__block {
        background-color: $color__bg--lt;
        padding-top: $space__inner;
        padding-bottom: $space__inner;
        .section__blockinner > div:not(.carousel__full-width) {
          @include container__fluid--nomobile($grid__gutter, calc($grid__gutter/2));
        }
      }
    }
    .section__block:has(.section__blockinner):has(.spotlight__full-width) {
      background-color: $color__prime;
    }
  }

  &:not(.section--overflow) {
    overflow: hidden;
  }

  &:not(.section--inactive) + &:not(.section--inactive) {
    margin-top: $space__section;
  }

  &--form {
    overflow: visible;
    z-index: $z__mid;
  }

  &--spaced {
    margin-bottom: $space__section;
  }

  &--nospace {
    margin-top: 0 !important;
  }

  &--z-med {
      z-index: 3;
  }

  &--padded-left {
      padding-left: $space__component;
  }
  
  &--inactive,
  &--initial-state {
    overflow: hidden;
    height: 0;
    visibility: hidden;
    padding: 0;
  }
  &--active {
    overflow: visible;
    height: auto;
    visibility: visible;    
  }

  &--lt {
    background-color: $color__bg--lt;
  }
  
  &--dk {
    background-color: $color__bg--dk-md;
  }

  &--prime {
    background-color: $color__prime;
  }

  &--collapse {
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  &__inner {
    position: relative;
    z-index: $z__base;
    visibility: visible;

    .section--padded & {
      padding-top: $space;
      padding-bottom: $space;
  
      @include respondTo('sm') {
        padding-top: $space__inner;
        padding-bottom: $space__inner;
      }
    }

    .section--accordion & {
      padding: $space 0;
    }

    .section--inactive & {
      &,
      .section--padded &,
      .section--accordion &  {
        padding: 0;
      }
    }
    
    &--fluid {
      @include container__fluid($grid__gutter, calc($grid__gutter/2));
    }

    &--fluid-nomboile {
      @include container__fluid--nomobile($grid__gutter, calc($grid__gutter/2));
    }

    &--limit {
      @include container__limit();
    }
  }

  &__block {
    position: relative;
    z-index: $z__base;

    &--lt {
      background-color: $color__bg--lt;
    }
    
    &--dk {
      background-color: $color__bg--dk-md;
    }
  
    &--prime {
      background-color: $color__prime;
    }
    
    & + & {
      margin-top: $space__component;
    }

    &--padded {
      padding-top: $space;
      padding-bottom: $space;
    }

    &--padded-left {
        padding-left: $space__component;
    }

    &--lt {
      background-color: $color__bg--lt;
    }

    &--no-margin {
      margin-top: 0 !important;
    }
    
    &--highlight {
        background-color: $color__aqua;
        padding: $space__component;
    }

    &inner {
      &--fluid {
        @include container__fluid($grid__gutter, calc($grid__gutter/2));
      }
      &--fluid-nomobile {
        @include container__fluid--nomobile($grid__gutter, calc($grid__gutter/2));
      }
    }
  }

  &__image {
    aspect-ratio: auto 311/180;
    display: block;
    height: 180px;
    object-fit: cover;

    &:not(&--natural-width) {
        width: 100%;
    }
  }

  &__title {
    margin-bottom: $space__paragraph;
    font-size: $fontsize__h3;
    @include fontWeight('bold');
    em {
      font-style: italic; 
    }
    &--sm {
    font-size: $fontsize__3;
    }
  }

  &__article {
    padding-top: $space__paragraph * 2;

    &:not(.section__block--highlight) {
      border-top: 1px solid $color__gray--D;
    }

    & + & {
        &:not(.section__block--highlight) {
          padding-top: 0;
        }
        border-top: none;
    }
  }

  &__meta {
    margin-right: .5em;
    text-transform: uppercase;
    color: $color__tan;

    &:not(:last-child) {
      &:after {
        display: inline-block;
        content: "|";
        margin-left: .5em; 
      }
    }
  }

  &__trigger {
    position: relative;
    display: block;
    width: 100%;
    font-size: $fontsize__4;
    @include interactive;
    @include fontWeight('bold');
    text-transform: uppercase;
    text-align: left;
    padding: $space $space__lg $space 0;
    border-bottom: 2px solid $color__gray--D;
    color: $color__gray--3;
  }

  &__trigger-icon {
    position: absolute;
    top: 50%;
    right: 0;
    @include translate(0, -50%);
    font-size: $fontsize__5;

    color: $color__black;

    &:before {
      @include icon($icon-plus);
    }

    .section__trigger--active & {
      &:before {
        @include icon($icon-minus);
      }
    }

  }

  @include respondTo('sm') {
    &__block {
      &--lt-nomobile {
        background-color: $color__bg--lt;
      }

      &--padded,
      &--padded-nomobile {
        
        padding-top: $space__inner;
        padding-bottom: $space__inner;
        
      }
    }
    &__image {
      aspect-ratio: auto 2/1;
      height: 220px;
    }
  }

  @include respondTo('md') {

    @include transition(none);    

    &--inactive,
    &--initial-state {
      height: 0;
      overflow: hidden;
    }
    &--active {
      height: auto;
      overflow: visible;
    }

    &__inner {
      padding: 0;

      .section--accordion & {
        padding: 0;
      }

      &--fluid {
        @include container__fluid($grid__gutter, calc($grid__gutter/2));
      }
    }

    &__title:not(.section__title--sm) {
      font-size: $fontsize__h2;
    }

    &__trigger {
      display: none;
    }

    &__image {
      height: 145px;
    }

  }

  &--noprint,
  &--form,
  &__trigger-icon {
    @include no-print()
  }

  @include respondTo('lg') {
    &__image {
      aspect-ratio: auto 2/1;
      height: 200px;
    }
  }

  @include respondTo('xl') {
    &__image {
      aspect-ratio: auto 2/1;
      height: 248px;
    }
  }

  @include respond-to-print {
      &__trigger {
          display: none;
      }

    &:not(.section--inactive) + &:not(.section--inactive) {
      margin-top: $space;
    }

    &__inner {
      &--fluid {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}