.messages {
  display: block;
  overflow: hidden;
  width: 100%;

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;

    & + & {
      margin-top: $space__sm;
    }

    &--success {
      background-color: $color__success--lt;
    }
    &--info {
      background-color: $color__info--lt;
    }
    &--warning {
      background-color: $color__warning--lt;
    }
    &--danger {
      background-color: $color__danger--lt;
    }

    &--inactive {
      position: relative;
      overflow: hidden;
      padding: 0;

      @include transition(max-height .6s ease, height .6s ease, height .6s ease);
    }
  }

  &__drawer {
    position: relative;
    padding: $space;
    color: $color__gray--3;
    background-color: inherit;
  }

  &__content {
    margin: $space 0 $space;
    background-color: $color__white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: rem(180px);
    width: 100%;
    overflow: hidden;

    &--slim {
        min-height: unset;
        margin: 0;
        margin-top: $space;
    }
  }

  &__col {
    position: relative;
    flex: 1 1;

    &--header {
      padding: $space;
    }
    &--media {
      width: 50%;
      height: 100%;
      min-height: inherit;
      overflow: hidden;
      @include backgroundFull();
    }
  }

  &__header {
    position: relative;
    text-align: center;
  }

  &__title {
    font-size: $fontsize__4;
    text-transform: uppercase;
    margin-right: $space;
    @include fontWeight('bold');
  }

  &__content-description {
    font-size: $fontsize__5;
    @include fontWeight('bold');
    word-break: break-word;
  }

  &__close {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: $z__top;
    display: inline-block;
    transform: translate(0, -50%);
    color: $color__gray--6;
    opacity: 1;
    visibility: visible;
    @include transition(opacity .6s ease, visibility .6s ease);

    &--inactive {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__icon {
    display: inline-block;
  }

  @include respondTo('sm') {
    &__title {
      font-size: $fontsize__4;
    }
  
    &__content-description {
      font-size: $fontsize__4;
    }

    &__col {
      position: relative;
      flex: 0 1 auto;
  
      &--header {
        padding: $space__md;
      }
      &--media {
        width: 45%;
      }
    }
  }

  @include respond-to-print {

    &__header {
      padding-left: 0;
    }

    &__content {
      min-height: 0;
    }
  }
}