.careers {
  margin: -2rem -2rem 0;

  &-detail {
    .section:not(.section--accordion) {
      padding-bottom: $space__xxl;
    }

    .careers-list {
      padding: 0;
    }

    .careers-list__opportunities {
      width: 100%;
      padding-right: 0;
    }

    // RTE For Careers
    .boldaccentgreen {
      color: $color__green--dk;
    }

    .bolddarkblue {
      color:  $color__prime--dk;
    }

    .darkgrey {
      color: $color__gray--6;
    }

    .button-careers {
      background-color: $color__green--dk;
      border-color:$color__green--dk;
      border: 1px solid;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      padding: 0.25em 1em;
      color: #ffffff;
      &:hover{
        text-decoration: none;
        color: white;
      }
    }
  }

  .spotlight {
    padding: $space__md $space;

    .component__title {
      text-align: left;
    }

    &__item {
      padding: 0;
    }

    .carousel--lg {
      overflow: visible;
    }

    .carousel__fullnav {
      top: -$space__lg;
    }
    
}

  @include respondTo('md') {
    margin: -8rem -2rem 0;
    padding-bottom: 0;

    .spotlight {
      padding: $space__xl $space__xxl;

      .component__title {
        font-size: $fontsize__careers-desktop;
        padding-bottom: $space;
      }

      &--wide {
        .spotlight__section--content {
          max-width: 100%;
        }
      }

      &__item {
        padding: 0 $space;
      }

      .carousel--lg {
        overflow: hidden;
      }

      .carousel__fullnav {
        top: 0;
      }
    }
  }
}