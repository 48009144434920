@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?o6j9wp') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?o6j9wp') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?o6j9wp##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-linkedin-blue {
  &:before {
    content: $icon-linkedin-blue; 
	color: #0077B5;
  }
}
.icon-x-logo {
  &:before {
    content: $icon-x-logo;     
    color: #000;
  }
}
.icon-clock-icon {
  &:before {
    content: $icon-clock-icon;     
    color: #000;
  }
}
.icon-quote {
  &:before {
    content: $icon-quote; 
  }
}
.icon-youtubenew {
  &:before {
    content: $icon-youtubenew; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-select-arrows {
  &:before {
    content: $icon-select-arrows; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-player {
  &:before {
    content: $icon-player; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-feed {
  &:before {
    content: $icon-feed; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-google-plus {
  &:before {
    content: $icon-google-plus; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-sphere {
  &:before {
    content: $icon-sphere; 
  }
}
.icon-ellipsis {
  &:before {
    content: $icon-ellipsis; 
  }
}
.icon-address-card {
  &:before {
    content: $icon-address-card; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}

