.careers-positions {
  padding: 0 0 $space__lg;

  &__title {
    color: $color__gray--3;
    font-size: $fontsize__h3;
    font-weight: $font__weight--bold;
    padding-bottom: $space__md;
    padding-top: $space__lg;
  }

  &__item {
    background-color: $color__gray--F2;
    padding: $space;

    &:nth-of-type(2n) {
      background-color: $color__white;
    }

    &-eyebrow {
      color: $color__gray--6;
      padding-bottom: $space__sm;
      font-size: $fontsize__h6;
      font-weight: $font__weight--bold;
      text-transform: uppercase;
    }

    &-title {
      color: $color__prime;
      font-size: $fontsize__h3;
      font-weight: $font__weight--bold;
    }

    &-date {
      font-size: $fontsize__h6;
      color: $color__gray--4F;
      padding-top: $space;

      &::before {
        @include icon($icon-clock-icon);
        color: $color__gray--4F;
        padding-right: $space__sm;
        padding-top: rem(1px);
      }
    }

    &-content {
      flex: 1 1 30%;
    }
  }

  &__listings {
    &-dropdowns {
      // display: flex;
      width: 100%;
    }
  }

  &__dropdown {
    display: block;
    width: 100%;
    background-color: $color__gray--82;
    color: $color__white;
    padding: $space__ty $space__sm;

    &--icon {
      position: relative;

      &::after {
        @include icon($icon-chevron-down);
        position: absolute;
        right: $space;
        top: $space__xt;
        color: $color__white;
        font-size: $fontsize__h3;
        pointer-events: none;
      }
    }

    &-container {
      // width: 50%;
      // padding-right: $space;
      padding-bottom: $space__md;
    }

    &-label {
      display: block;
      color: $color__gray--3;
      font-size: rem(15px);
      padding-bottom: $space__sm;
    }
  }

  &__pagination {
    padding-top: $space__md;
  }

  &__subtitle {
    display: inline-block; 
    font-size: $fontsize__h5;
    font-weight: $font__weight--bold;
    margin-bottom: $space__md;
  }

  @include respondTo('md') {
    &__item {
      display: flex;

      &-date {
        padding-top: 0;
      }
    }
    
    &__listings {
      &-dropdowns {
        display: flex;
      }
    }

    &__dropdown {
      &-container {
        width: 50%;
        padding-right: $space;
      }
    }
  }
}