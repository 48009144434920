
.professional__detail {
  &__rich-text-list {
    .additional-enagements-tile {
      padding-top: 1rem;
    }
    .past-speaking-engagements {
      padding-left: 1rem;
    }
    .eventlist {
      li {
        display: inline-block;
        font-size: $fontsize__6;
        @include fontWeight('bold');
        text-transform: uppercase;
        color: $color__tan;
        b {
          text-transform: none;
          font-size:  $fontsize__4;
          line-height: 1.25em;
          margin-bottom: $space__sm;
          color: black;
        }
        .bold {
          display: block;
          font-size: $fontsize__6;
          @include fontWeight('bold');
          text-transform: uppercase;
          color: $color__tan;
        }
        .articleblock__title {
          text-transform: none;
          font-size:  $fontsize__4;
          line-height: 1.25em;
          margin-bottom: $space__sm;
          color: black;
        }
      }
    }
  }
}