﻿.professional-landing {

    .CoveoBreadcrumb {
        margin-top: 2rem;
    }

    #button__advanced {
        padding-left: 0;
    }

    &__resultsummary {
        background-color: $color__white;
        margin-top: 4rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $color__gray--4;

        &--hidden {
            display: none;
        }

        .CoveoQuerySummary {
            color: $color__gray--4;
            max-width: 35%;
            display: inline-block;
        }

        .coveo-sort-container {
            float: right;
        }
    }

    &__breadcrumbs {
        margin-top: 1rem;
        padding-top: 0;
        background-color: $color__white;
        border-bottom: 1px solid $color__gray--4;

        &--hidden {
            display: none;
        }

        @include respondTo('sm') {
            margin-top: 0;
            padding-top: 2rem;
            /*margin-left: -2rem;
            margin-right: -2rem;
            margin-bottom: -2rem;*/
            //padding: 2rem;
        }

        .CoveoBreadcrumb {
            margin-top: 0;
            margin-bottom: 0;
        }

        .coveo-facet-breadcrumb-clear {
            background-color: $color__gray--4 !important;
        }

        .coveo-facet-breadcrumb-clear-svg {
            color: $color__gray--4 !important;
        }

        .coveo-facet-breadcrumb-value {
            border-color: $color__gray--4 !important;
        }

        .coveo-facet-breadcrumb-title,
        .coveo-facet-breadcrumb-caption {
            color: $color__gray--4 !important;
        }

        .coveo-breadcrumb-clear-all {
            border-color: $color__gray--4 !important;
            color: $color__gray--4 !important;

            svg {
                background-color: $color__gray--4;
            }
        }
    }

    .coveo-results-column {
        background-color: $color__white;
        margin-top: 1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        margin-left: -1rem;

        @include respondTo('sm') {
            margin-top: 2rem;
            margin-right: -2rem;
            margin-bottom: -2rem;
            margin-left: -2rem;
        }

        &--hidden {
            display: none !important;
        }
    }

    .coveo-result-frame {
        .professional__container {
            display: flex;
            flex-direction: column;

            .professional__row--horizontal {
                display: flex;
                flex-direction: row;
                padding: 1rem;
                color: $color__black;
                transition: $transition__interactive;
                text-decoration: none;

                &:hover {
                    color: $color__gray--5;
                }

                .professional__column--left {
                    flex: 0 0 auto;
                }

                .professional__column--right {
                    flex: 1 1 auto;
                    padding: 1rem;
                }

                .professional__column--full {
                    width: 100%;
                }

                .person__media {
                    height: auto;
                    max-height: 9rem;
                    max-width: 13.125rem;
                }

                .person__image {
                    width: 100%;
                    display: block;
                }

                .person__meta {
                    .person__phone {
                        color: inherit;

                        &-listing::before {
                            content: "+ "
                        }
                    }
                }

                @include respondTo('sm') {
                    .person__left {
                        width: 200px;
                    }
                }

                @media screen and (max-width: 575px) {
                    flex-direction: column;
                }
            }
        }
    }
    /*@include respondTo('sm') {
        margin-top: 2rem;
    }*/
    .CoveoSearchbox {
        width: 100%;
    }

    .form {
        background-color: $color__gray--4;
        margin: -1rem;
        padding: 1rem;

        @include respondTo('sm') {
            margin: -2rem;
            padding: 2rem;
        }

        &__subheader {
            display: none;
        }

        &__inner {
            display: flex;
            flex-direction: column;
        }

        &__col {
            width: 100% !important;
            margin-top: 1rem;

            &--alpha {
                width: 100%;
            }
        }

        &__row {
            width: 100%;

            @include respondTo('sm') {
                width: calc(50% - 7px);
            }
        }

        .readmore__block {
            margin-top: 0 !important;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto !important;
            flex-wrap: wrap;
            justify-content: space-between;
            overflow: visible;

            @include respondTo('sm') {
                flex-direction: row;
            }
        }

        .readmore__more-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }


        .button__bar {
            margin-top: 0;
        }

        .coveo-facet-search-magnifier {
            display: none !important;
        }

        .coveo-facet-search-input {
            cursor: pointer;
        }
    }

    .readmore__more-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .coveo-footer {
        .CoveoPager {
            margin-top: 10px;
            margin-left: 10px;
        }

        &--hidden {
            display: none !important;
        }
    }
}
