//COPY DIRECTLY FROM ICOMOON FILE fonts/icomoon/style.css
// INSTRUCTIONS:
//	1) to update files go to https://icomoon.io/app/#/select
//	2) import icons from selection.json
//	3) copy whole folder to replace fonts/icomoon
//	4) copy style.css to this file
//	5) update font path

@import '../fonts/icomoon/style';
// $icomoon-hash: '5ekmvc'; //NOTE: copy version code here
// $font-path-icomoon: '/icomoon/fonts/icomoon';
// $font-family-icomoon: 'icomoon';


// @include font-face-family($font-family-icomoon, $font-path-icomoon, 'normal', 'normal');

// @mixin iconStyle() {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   @include fontWeight('normal');
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;
//   // vertical-align: middle;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

@mixin icon($icon-name) {
  @extend [class^="icon-"];
  content: $icon-name;
  display: inline-block;
}

// [class^="icon-"],
// [class*=" icon-"] {
//   &:before,
//   &:after {
//     @include iconStyle();
//   }
// }

// .icon-search--menu {
//   font-size: 16px;
//   margin-left: 8px;
// }

// //NOTE: Copy from fonts/icomoon/style.css //When including new icons, copy and paste from icomoon below
// .icon-select-arrows:before {
//   content: "\e914";
// }
// .icon-search:before {
//   content: "\e900";
// }
// .icon-phone:before {
//   content: "\e919";
// }
// .icon-play:before {
//   content: "\e918";
// }
// .icon-pause:before {
//   content: "\e917";
// }
// .icon-player:before {
//   content: "\e917";
// }
// .icon-close:before {
//   content: "\e901";
// }
// .icon-minus:before {
//   content: "\e902";
// }
// .icon-plus:before {
//   content: "\e903";
// }
// .icon-pin:before {
//   content: "\e904";
// }
// .icon-linkedin:before {
//   content: "\e905";
// }
// .icon-feed:before {
//   content: "\e906";
// }
// .icon-twitter:before {
//   content: "\e907";
// }
// .icon-facebook:before {
//   content: "\e908";
// }
// .icon-google-plus:before {
//   content: "\e909";
// }
// .icon-share:before {
//   content: "\e90a";
// }
// .icon-sphere:before {
//   content: "\e90b";
// }
// .icon-ellipsis:before {
//   content: "\e91b";
// }
// .icon-address-card:before {
//   content: "\e91a";
// }
// .icon-envelope:before {
//   content: "\e90c";
// }
// .icon-youtube:before {
//   content: "\e90d";
// }
// .icon-external-link:before {
//   content: "\e91c";
// }
// .icon-menu:before {
//   content: "\e916";
// }
// .icon-print:before {
//   content: "\e915";
// }
// .icon-bookmark:before {
//   content: "\e90e";
// }
// .icon-download:before {
//   content: "\e90f";
// }
// .icon-chevron-right:before {
//   content: "\e910";
// }
// .icon-chevron-left:before {
//   content: "\e911";
// }
// .icon-chevron-down:before {
//   content: "\e912";
// }
// .icon-chevron-up:before {
//   content: "\e913";
// }
// .icon-quote:before {
//   content: "\e91d";
// }
// .icon-clock-icon:before {
//   content: "\e91e";
// }