.typeahead {
  $size__typeahead: calc($size__hero/2);
  position: relative;

  &__results {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: $z__top;
  }

  &__list {
    overflow: auto;
    height: 0;
    opacity: 0;
    visibility: hidden;
    background-color: $color__white;
    box-shadow: 0 0 6px rgba($color__black, 0.2);
    @include transition(opacity .6s ease, height .6s ease, visibility .6s ease);

    &--active {
      height: $size__typeahead;
      max-height: calc(100vh - #{$size__header--mobile});
      opacity: 1;
      visibility: visible;
    }
  }

  &__trigger {
    cursor: pointer;
    display: block;
    width: 100%;
    padding: $space__ty $space;
    text-align: left;
    @include transition(background-color .6s ease); 
    @include interactiveColor($color__text, lightent($color__text, 10%));
    background-color: $color__white;

    &:hover {
      background-color: $color__gray--E;
    }
  }

}