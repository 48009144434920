.page {
  position: relative;
  display: block;
  background: $color__bg;

  > * {
    flex: 1 0 auto;
  }

  &__main,
  &__aside {
    position: static;
    display: block;
    width: 100%;
  }

  &__main {
    padding-top: $size__header--mobile;
    
    > * {
      position: relative;
      z-index: $z__base;
    }

    @include container__main();
  }

  &__aside {
    position: fixed;
    height: $size__header--mobile;
    background-color: $color__bg--dk;
    z-index: $z__primary; 

    @include respondTo('md') {
      position: relative;
      z-index: $z__header + 1;
      height: auto;
      &--alt {
        background-color: rgba($color__bg--dk, 0.65);
      }
    }
    @include container__sidebar();

    @include no-print();
  }

  &__content {
    position: relative;
    display: block;
    @include container__fluid(($space__component*2));
    
    padding-top: $space__component;
    padding-bottom:  $space__component * 2;

    &--full {
      padding: 0;
    }

    &--full-left {
        padding-left: 0;
    }
  }

  &__media {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z__behind;
    width: 100%;
    height: 100%;
   
    @include backgroundFull();
    background-position: center top;
    background-color: $color__dark;
    @include translateZ(0);

    &--nomobile {
      background-size: auto;
    }

    &--overlay {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color__prime, 0.35);
      }
    }

    &--mobile-fixed {
      background-attachment: fixed;
    }
  }

  @include respondTo('md') {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;

    > * {
      flex: 1 0 auto;
    }

    &__main {
      padding-top: $size__header;
    }

    &__main--home {
      padding-top: 0;
    }

    &__content {
      padding-bottom:  $space__component * 3;
      position: static;

      &--full {
        padding: 0;
      }
    }

    &__media {
      &--nomobile {
        background-size: cover;
        height: 100%;
      }

      &--mobile-fixed {
        background-attachment: inherit;
      }
    }

    &__image {
      display: none;
    }
  }

  @include respond-to-print() {
    &__content {
      padding: 0 $space;
    }
    &__main {
      padding-top: 1.5rem;
    }
    &__aside {
      position: relative;
    }
    &__media {
      display: none;
    }
  }
}

.max70 {
  @include respondTo('xxl') {
    max-width: calc(75% - #{$space__md});
  }
}