.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  overflow: hidden;

  &--active {
    z-index: $z__modal;
    opacity: 1;
  }

  &__close {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: $z__ahead;
    transform: translateY(-100%);
    background-color: $color__white;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 35px;
    height: 35px;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z__ahead;
    transform: translate(-50%, -50%);
    background-color: $color__bg;
    padding-top: $space__md;
    padding-bottom: $space__md;
  }
}