.testimony {
  &__details {
    @include blockQuote();
    line-height: 1.15em;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: $space;
    border: none;

    &--no-quote {
      &:before {
        content: none;
        margin-right: 0;
      }
      &:after {
        content: none;
        margin-left: 0;
      }
    }
  }
  
  &__cite {
    color: $color__gray--6;
    font-size: $fontsize__h5;
    font-style: italic;

    &:before {
      display: inline-block;
      content: "\2014";
      margin-right: .25em;
    }
  }
}