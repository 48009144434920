.locationslist {
  &__item {
    display: block;
    border-bottom: 2px solid $color__gray--8;
    width: 90%;
    margin-left: $space__tile--mobile;
    margin-right: $space__tile--mobile;

    &:last-of-type {
      border-bottom: none;
    }

    &--country-name {
      margin-left: (-1*$space__tile--mobile);
      width: 110%;
      border-bottom: none;
      opacity: 0.85;
    }

    &--country-name-no-text {
      display: none;
    }
  }
  
  &__link {
    display: block;
    padding: $space__sm 0;
    font-size: $fontsize__5;
    line-height: 1.4em;
    @include fontWeight(light);
    @include interactiveColor($color__black, $color__gray--6);
    
    &--country-name {
      width: 90%;
      color: $color__white;
      @include fontWeight(normal);
      text-align: center;
      @include interactiveColor($color__white, $color__white);
    }
  }

  &__grouping {
    margin-left: (-1*$space__tile--mobile);
    width: 110%;
    background-color: $color__white;
  }

  @include respondTo('md') {
    //column-count: 4;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: row;
    width: 100%;

    &__item {
      border-bottom: none;
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &--country-name {
        margin-left: 0;
        width: 100%;
        opacity: 1;
      }

      &--country-name-no-text {
        display: block;
      }
    }

    &__link {
      padding: 0;
      @include interactiveColor($color__white, rgba($color__white, 80%));

      &--country-name {
        height: 1.4em;
        width: 100%;
        @include fontWeight(bold);
        text-transform: uppercase;
        text-align: left;
      }
    }

    &__grouping {
      columns: 4;
      margin-left: 0;
      width: 100%;
      background-color: transparent;

      &--two-column {
        columns: 2;
      }
    }
  }

  @include respondTo('xl') {
    &__link {
      font-size: $fontsize__4;
    }
  }
}