.footer {
  position: relative;
  z-index: $z__base;

  &__row {
    position: relative;
    width: 100%;
    padding-top: $space__md;
    padding-bottom: $space__md;

    background-color: $color__gray--F;

    &--tall {
      padding-bottom: $space__xl;
    }

    &--lt {
     background-color: $color__white;
    }

    .copyright {
      text-align: center;
      margin-top: 10px;
    }
  }

  &__section {
    &:first-child {
      margin-bottom:  $space__md;
      border-bottom: 1px solid $color__gray--C;
    }
  }

  @include respondTo('md') {
    &__sections {
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__row {

  
      &--limit {
        
      }
    }

    &__section {
      flex: 0 0 auto;

      &:last-child {
        margin: 0;
        padding: 0;
        border: 0;
        margin-right:  $space__md;
        padding-right: $space__md;
        border-right: 1px solid $color__gray--C;
      }

      &:first-child {
        flex: 1 0 auto;
        border: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  @include no-print();
}