.pagetools {

  &__block {
    position: relative;
    display: block;
  }

  &__print {
    display: none;
  }

  &__lang {
    margin-top: 20px;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    @include transition(max-height .6s ease);

    &--active {
      visibility: visible;
      max-height: 999px;
    }
  }

  @include respondTo('md') {
    display: none;
    
    &__print {
      display: inline-block;
    }

    &__lang {
      margin-top: 0;
    }
  }
}