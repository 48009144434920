.careers-tabs {
  background-color: $color__prime;
  color: $color__white;
  padding: $space__xxl $space $space__lg;


  .boldfont {
    font-weight: 700;
  }

  &__container {

    &--desktop {
      display: none;
    }
  }

  &__title {
    font-size: $fontsize__h2;
    font-weight: $font__weight--bold;
    color: $color__white;
    padding-bottom: $space__lg;

    &--blue {
      color: $color__second;
    }
  }

  &__tab {
    cursor: pointer;
    font-size: $fontsize__h4;
    border-bottom: 1px solid $color__white;
    padding-bottom: $space;
    margin-bottom: $space;
    position: relative;

    &::after {
      @include icon($icon-plus);
      position: absolute;
      right: 0;
      transition: transform .3s ease-in-out;
    }

    &--active {
      &.careers-tabs__tab {
        &::after {
          @include icon($icon-minus);
        }
      }
    }

    &-content {
      font-size: $fontsize;
      transition: transform .3s ease-in-out;
      transform: scaleY(0);
      transform-origin: top;
      overflow: hidden;
      height: 0;
      max-width: 60rem;

      &--active {
        transform: scaleY(1);
        height: 100%;
      }
    }

    &-description {
      margin-bottom: $space;
    }
  }

  &__quote {
    text-align: center;
    padding-bottom: $space__lg;

    &-icon {
    text-align: center;

      &:before {
        @include icon($icon-quote);
        color: $color__aqua;
        font-size: $fontsize__h1;
        padding: $space__md 0;
      }
    }
  }



  @include respondTo('md') {
    padding: $space__xxl;

    &__container {
      display: none;

      &--desktop {
        display: block;
      }
    }

    &__title {
      font-size: $fontsize__careers-desktop;
    }

    &__tab-container {
      display: flex;
      margin-top: $space__md;
      margin-bottom: $space__lg;
    }
  
  
    &__tab {
      border: 1px solid $color__white;
      font-size: $fontsize__h5;
      margin-right: $space__sm;
      padding: $space__ty $space;
      color: $color__white;
      transition: color .4s cubic-bezier(.165,.84,.44,1),background-color .4s cubic-bezier(.165,.84,.44,1);
  
      &--active {
        background-color: $color__second;
        color: $color__gray--3;

        &.careers-tabs__tab {
          &::after {
            content: none;
          }
        }
      }

      &:hover {
        background-color: $color__second;
        color: $color__gray--3;
      }

      &-content {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        height: auto;
        transform: none;
      }

      &-description {
        width: 100%;
        margin-bottom: 0;
      }

      &::after {
        content: none;
      }
    }

    &__quote {
      padding-left: $space__lg;
      width: 75%;
    }


  }
}