// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126
// License: none (public domain)

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, del, dfn, em, img, q, s, samp, small, strike, strong, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, mark, audio, video, address {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

body {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type],
button,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-style: solid;
}

button {
  padding: 0;
}


input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

input[type=range]::-ms-track {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
  overflow: visible;
}

input[type=range]::-moz-range-track {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
  overflow: visible;
}

input[type=range] {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
  overflow: visible;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}