.carousel {
  $speed__carousel: 1200ms;
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: inherit;

  &__pagination {
    display: flex;
  }

  &--tiles {
    min-height: $size__tile--ty;
  }

  &--tiles-home {
    background-color: $color__gray-blue;

    .carousel__circle {
      background-color: rgba(0,0,0,0);
    }
  }

  &--lg {
    display: flex;
    flex-flow: row nowrap;
    padding-top: $space__md;

    > * {
      flex: 0 0 auto;
    }
  }

  &__items {
   .carousel--lg & {
    flex: 1 0 auto;
   }

    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: inherit;
  }

  &__title {
    padding-left: 3rem;
  }

  &__item {
    .carousel & { //nested to force context
      position: absolute;
    }
    top: 0;
    left: 100%;
    z-index: $z__base;
    visibility: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;
    @include transition(none);

    &--active,
    &--previous {
      visibility: visible;
      @include transition(left $speed__carousel ease);
      opacity: 1;
    }

    &--active {
      left: 0;
      visibility: visible;
    }

    &--previous {
      left: -100%;
    }

    .carousel--backward & {
      left: -100%;
      @include transition(none);

      &--active,
      &--previous {
        @include transition(left $speed__carousel ease);
        opacity: 1;
      }

      &--active {
        left: 0;
      }

      &--previous {
        left: 100%;
      }
  
    }
  }

  &__nav {
    position: relative;
    z-index: $z__top;

    &--home {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }

    &--insights {
      display: flex;
      align-items: center;
    }
  }

  &__fullnav {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;

    &--lt {
      right: $space__md;
    }
  }

  &__nav-item {
    display: inline-block;
    
    margin-left: $space__xt;
    margin-right: $space__xt;
    line-height: normal;
    vertical-align: middle;

    &--nomobile {
      display: none;
    }

    .carousel__fullnav & {
      position: relative;
      margin: 0;
      font-size: $fontsize__3;
    }
  }

  &__transport {
    font-size: $fontsize--3;
    line-height: 0;
    cursor: pointer;
    @include interactiveColor($color__aqua, $color__white);
  }

  &__trigger {
    cursor: pointer;
  }

  &__circle {
    display: block;
    width: 18px;
    height: 18px;
    background-color: $color__prime;
    border: 2px solid $color__second--dk;
    border-radius: 100%;

    &--lt {
      border-color: $color__gray--D;
    }
  }
  
  @include respondTo('sm') {

    &--lg {
      padding-top: 0;
    }

    &__fullnav {
      position: relative;
      left: auto;
      right: auto;
      min-height: 100%;
      width: $size__md;
    }

    &__nav-item {
      display: inline-block;

      .carousel__fullnav & {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        margin: 0;
        @include translate(-50%, -50%);
        font-size: $fontsize__2;
      }
    }
    
  }

  @include respondTo('md') {
    &--tiles {
      min-height: $size__tile--sm;
    }

    &__nav {
      position: absolute;
      left: $space;
      top: $space;
      z-index: $z__top;

      &--home {
        left: auto;
        top: auto;
        right: $space;
        bottom: $space;
        text-align: right;
      }
    }
  }

  @include respondTo('xl') {
    &__fullnav {
      width: $size__lg;
    }
  }

  &__nav,
  &__fullnav {
    @include no-print();
  }

  @include respond-to-print {
    &__item {
      .carousel & { //nested to force context
        position: relative;
      }

      .tile {
        overflow: visible;
      }
    }
    &__nav {
      display: none;
    }
    &--lg {
      padding-top: $space;
    }
  }
}