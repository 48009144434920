.breadcrumbs {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &__item {
    display: inline-block;
    color: $color__gray--5;
    @include fontWeight('bold');
    font-size: $fontsize__6;
    line-height: 1.2;
    text-transform: uppercase;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
      content: ">";
      display: inline-block;
      margin: 0 .5em;
    }
    &:last-child {
      &:after {
        content: "";
        display: none;
      }
    }
  }

  &__text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;    
  }

  &__link {
    @include interactiveColor($color__gray--3, $color__gray--6);
  }
}