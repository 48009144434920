.fullvideo {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &__video {
    display: none;
    position: relative;
    z-index: $z__behind;

    &--home {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__placeholder-image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20rem;
    
    @include backgroundFull();
    background-color: $color__dark;
  }

  @include respondTo('md') {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      z-index: $z__ahead;
      @include gradient-vertical(rgba($color__bg--dk, 0), $color__bg--dk, 50%);
    }

    &__video {
      display: block;
    }

    &__placeholder {
      display: none;
    }
  }

  @include respondTo('sm') {
      &__placeholder-image {
        max-height: 50vh;
    }
  }
}