.news-insights {
  background-color: $color__gray--3;
  padding: $space__xl $space__md;
  color: $color__white;

  &__title {
    font-size: $fontsize__h3;
    padding-bottom: $space__lg;
    font-weight: $font__weight--bold;

    &--blue {
      color: $color__aqua;   
    }
  }

  &__article {
    display: inline-block; 
    height: 100%;
    cursor: pointer;
    color: $color__white;
    text-align: start;

    &-tag {
      padding-top: $space__md;
    }

    &-title {
      font-size: $fontsize__h3;
      font-weight: $font__weight--bold;
      color: $color__white;
      padding-top: $space;
      display: inline-block;
    }

    &-body {
      padding-top: $space;
    }

    &-view-more {
      font-size: $fontsize__h5;
      font-weight: $font__weight--bold;
      color: $color__white;
      padding-top: $space;
      padding-bottom: $space__xl;
      display: inline-block;
    }

    .news-insights__article-tag,
    .news-insights__article-title,
    .news-insights__article-body,
    .news-insights__article-view-more {
      transition: color .4s cubic-bezier(.165,.84,.44,1),background-color .4s cubic-bezier(.165,.84,.44,1);
    }

    &:hover {
      .news-insights__article-tag,
      .news-insights__article-title,
      .news-insights__article-body,
      .news-insights__article-view-more {
        color: $color__second;
      }
    }
  }

  @include respondTo('md') {
    padding: $space__xl $space__xxl;
    &__container {
      display: flex;
    }

    &__title {
      font-size: $fontsize__careers-desktop;
    }

    &__article {
      margin-right: $space__lg;
      width: 100%;
      flex: 0 1 30%;

      &-image {
        width: 100%;
      }
    }
  }
}