@mixin interactive {
  cursor: pointer;
}

@mixin interactiveColor($color: $color__link, $colorhover: false) {
    color: $color;
    @include transition($transition__interactive);

    &:hover,
    &--active {
      @if $colorhover {
        color: $colorhover;
      } @else {
        color: darken($color, 15%);
      }
    }
}

@mixin interactiveColorTwo($color: $color__link, $colorhover: false) {
  color: $color;
  @include transition($transition__interactive);

  &:hover {
    @if $colorhover {
      color: $colorhover;
    } @else {
      color: darken($color, 15%);
    }
  }
}

@mixin input() {
  position: relative;
  display: inline-block;
  vertical-align: middle; 
  @include interactive;
}

@mixin inputSize( $font-size: 1rem, $input-height: $size__input, $padding-vertical: .25em, $padding-horizontal: 1em, $line-height:  $size__input, $border-radius: 0) {
  min-height: $input-height;
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

@mixin inputColor($color: $color__input--tx, $bg: $color__input--bg, $border: $color__input--br, $colorhover: false, $bghover: false, $bdrhover: false) {
  &,
  &:visited {
    background-color: $bg;
    border-color: $border;
  }

  @if $colorhover {
    @include interactiveColor($color, $colorhover);
  } @else {
    @include interactiveColor($color, $bg);
  }

  &,
  &:visited,
  &--focus {
    background-color: $bg;
    border-color: $border;
  }

  &:hover,
  &--active  {
    border-color: $border;

    @if $bghover {
       background-color: $bghover;
    } @else {
       background-color: $color;
    }

    @if $bdrhover {
      border-color: $bdrhover;
    } @else if $bghover {
      border-color: $bghover;
    } @else {
      border-color: $color;
    }
  }
  &:disabled,
  &--disabled,
  &:disabled:hover,
  &--disabled:hover {
    cursor: default;
    color: $color__gray--6;
    border-color: $color__gray--6;
    background-color: $color__gray--E;
  }
}

@mixin customInputColor($color: $color__input--tx, $bg: $color__input--bg, $border: $color__input--br, $colorhover: true, $bghover: true) {
  opacity: 0.85;
  &,
  &:visited {
    border-color: $border;
  }
  
  &--focus {
    opacity: 1;
    border-color: $border;
  }

  &:hover,
  &--active  {
    border-color: $border;
    opacity: 1;
  }

  &:disabled,
  &--disabled,
  &:disabled:hover,
  &--disabled:hover {
    cursor: default;
    color: $color__gray--6;
    border-color: $color__gray--6;
    background-color: $color__gray--E;
    opacity: 0.85;
  }
}