.input {
  position: relative;
  display: inline-block;

  &__label {
    display: block;
    margin-bottom: calc($space__paragraph/2);
    width: 100%;
  }

  &__select {
    border: 1px solid;
    // text, background, border, text hover, background hover
    @include inputColor($color__select--tx, $color__select--bg, $color__select--br, $color__select--tx, $color__select--bg, $color__select--br);
    max-width: 100%;
    width: auto;
    overflow: hidden;

    &:before {
      position: absolute;
      right: 0;
      top: 50%;
      z-index: $z__behind;
      @include icon($icon-chevron-down);
      @include translate(-50%, -50%);
      color: $color__select--tx;
    }
    &--lt {
      @include inputColor($color__white, $color__gray--6, $color__gray--6, $color__white, $color__gray--6, $color__gray--6);
      &:before {
        color: $color__white;
      }
    }
  }

  &--full {
    display: block;
    width: 100%;
  }

  &__search {
    &--collapse {
      overflow: hidden;
      @include transition(width .6s ease);
      width: 0px;

      &-open {
        overflow: visible;
        width: 100%;
      }
    }
  }

  &__in {
    border-style: solid;
    @include input();
    @include inputSize($fontsize, $size__input);
    @include inputColor($color__input--tx, $color__select--bg, $color__select--br, $color__input--tx, $color__select--bg);
    z-index: $z__ahead;
    border-width: 1px;

    &[type=text],
    &[type=url],
    &[type=tel],
    &[type=number],
    &[type=color],
    &[type=email] {
      cursor: text;
    }

    .input--full & {
      display: block;
      width: 100%;
    }

    .input__select & {
      display: block;
      width: 100%;
      min-height: $size__input;
      line-height: $size__input;
      padding-right: $space__md;
      background: transparent;
      border: 0;
      color: inherit;
      -webkit-user-select: none;
      -moz-user-select: -moz-none;
       -ms-user-select: none;
           user-select: none;
   
      -webkit-appearance: none;
      -moz-appearance: radio-container;
           appearance: none;
           &::-ms-expand { display: none; }

      > option {
        color: $color__black;
      }
    }

    .input__area &,
    .input__multi & {
      height: auto;
      line-height: $size__input;
    }

    .input__radio &,
    .input__checkbox & {
      position: relative;
      padding: 0;
      opacity: 0;
      width: $size;
      height: $size;
      min-height: $size;
      border: 0;

      &:checked ~ .input__icon {
        background: $color__prime;
      }
    }

    .input__search & {
      padding-right: $space__md;
      z-index: $z__behind;
      width: 100%;
      border: none;
      @include fontWeight(bold);
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .input--dk & {
      @include inputColor($color__white, $color__gray--3, $color__gray--3, $color__white, $color__gray--3, $color__gray--3);
      @include placeholder($color__white);
    }

    .input--lt & {
      @include inputColor($color__white, $color__gray--6, $color__gray--6, $color__white, $color__gray--6, $color__gray--6);
      @include placeholder($color__white);
    }

    .input--ltr & {
      @include inputColor($color__gray--5, $color__gray--D, $color__gray--D, $color__gray--5, $color__gray--D, $color__gray--D);
      @include placeholder($color__gray--5);
    }

    .input--lg & {
      @include inputSize($fontsize__5, $size__input, 1em, 1em);
      font-size: $fontsize;

      @include respondTo('sm') {
        font-size: $fontsize__5;
      }
    }
    .input--md & {
      @include inputSize($fontsize__5, $size__input, .8em, .8em);
      font-size: $fontsize;
      
      @include respondTo('sm') {
        font-size: $fontsize__5;
      }
    }

    .input__search.input--lg &,
    .input__search.input--md & {
      padding-right: $size__searchicon * 2.5;
    }
    
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    z-index: $z__behind;

    .input--dk & {
      color: $color__white;
    }

    .input__search & {
      z-index: $z__ahead;
      right: $space;
    }

    .input--lg & {
      font-size: $size__searchicon;
    }

    .input--xl & {
      font-size: $fontsize__3;
    }

    .input__radio & ,
    .input__checkbox & {
      width: $size;
      height: $size;
      background: $color__white;
      border: 2px solid $color__prime--dk;
    }

    .input__radio & {
      border-radius: 100%;
    }

    .input__checkbox & {
      border-radius: 6px;
    }

    .input__select & {
      right: $space;
      transform: translate(50%, -50%);
    }
  }

  &__searchtoggle {
    position: absolute;
    left: 0;
    top: 0;
    z-index: $z__ahead;
    height: $size__header--mobile;
    line-height: 100%;
    padding: 0 $space;
    display: none;
    font-size: $size__searchicon;
    color: $color__white;
    background-color: $color__gray--3;
    &--ready {
      display: inline-block;
    }
    &--active {
      display: none;
    }
  }

  // &__area {}
  // &__password {}

  @include respondTo('sm') {
    &__in {
      .input--xl & {
        @include inputSize($fontsize__1, $size__input, .25em, .5em);
      }
    }
    &__icon {
      .input--xl & {
        font-size: $fontsize__1;
      }

      .input--ltr & {
        color: $color__gray--5;
      }
    }
  }

  @include respondTo('md') {
    &__in {
      @include fontWeight('bold');

      .input--xl & {
        @include inputSize($fontsize__0, $size__input, .25em, .5em);
      }
    }
    &__icon {
      .input--xl & {
        font-size: $fontsize__0;
      }
      .input__searchfull & {
        right: 0;
      }
    }
  }

}