.component {

  & + &:not(&--no-spacing) {
    margin-top: $space__component;
  }

  .accordion__item + &:not(&--no-spacing) {
    margin-top: $space__component;
  }

  &--lt {
    padding: $space__inner $space__inner $space__inner;
    background-color: $color__bg--lt;
  }

  &__title {
    position: relative;
    @include fontWeight('bold');
    font-size: $fontsize__3;
    margin-bottom: $space__paragraph;
    text-transform: uppercase;

    &--careers {
      font-size: $fontsize__4;
    }

    &--capitalize {
        text-transform: capitalize;
    }

    &--shortmobile  {
      margin-bottom: 0;
    }

    .component--side & {
      font-size: $fontsize__5;
      color: $color__gray--3;
    }

    &--flex {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
      > * {
        flex: 0 0 auto;
      } 
    }

    &--lt {
      color: $color__light;
    }

    &--blue {
      color: $color__aqua;
    }
  }

  &__subtitle {
    font-size: $fontsize__4;
    font-weight: $font__weight--bold;
    padding-bottom: $space__md;

    &--careers {
      font-size: $fontsize__5;
    }
  }

  &__localtimetitle {

      &#{&} { 
        font-size: $fontsize__5;
      }
  }

  &__accordionbtn {
    position: absolute;
    right: 0;
    top: 50%;
    @include translate(0, -50%);
    @include interactive;
    @include interactiveColor($color__white, $color__aqua);
  }

  &__description {
    margin-bottom: $space__component;
  }

  &__litetext {
    color: $color__gray--3;
  }

  &__listitem {
    line-height: 1.25em;
    margin-bottom: $space__sm;
  }

  &__video {
    overflow: hidden;    
    position: relative;
    // width: 100%;
    max-width: 100vh;
    max-height: 100%;
    margin: 0;

    &:before {
      content: "";
      display: block;
      margin-top: 56.5%;
      z-index: 0;
    }

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  

  @include respondTo('md') {

    &__title {
      font-size: $fontsize__3;
      
      &--careers {
        font-size: $fontsize__4;
      }

      &--smmd {
        font-size: $fontsize__4;
      }

      &--shortmobile  {
        margin-bottom: $space__paragraph;
      }

      &--nodesktopspace {
        margin-bottom: 0;
      }
    }
  }

  @include respondTo('xl') {

    &__title {
      font-size: $fontsize__3;
      &--careers {
        font-size: $fontsize__4;
      }
    }
  }
}