.richtext {
  @include clearfix();
  @include fontWeight('normal');

  line-height: 1.6;
  font-size: $fontsize;
  
  p {
    margin-bottom: $space__paragraph;
  }
  a,
  &__a {
    text-decoration: $font__decor--link;
  
    &:focus {
      text-decoration: $font__decor--link-hover;
    }
  
    &:hover,
    &:active {
      text-decoration: $font__decor--link-hover;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  &__h1,
  &__h2,
  &__h3,
  &__h4,
  &__h5,
  &__h6 {
    margin-bottom: $space__paragraph;
    line-height: normal;
    @include fontWeight('bold');
  }
  
  h1,
  &__h1 { font-size: $fontsize__h1; }

  h2,
  &__h2 { font-size: $fontsize__h2; }
  h3,
  &__h3 { 
    font-size: $fontsize__h3; 
    margin-bottom: $space__paragraph !important;
  }
  h4,
  &__h4 { font-size: $fontsize__h4; }
  h5,
  &__h5 { font-size: $fontsize__h5; }
  h6,
  &__h6 { font-size: $fontsize__h6; }

  p,
  &__p {
    margin-bottom: $space__paragraph;

    .richtext--highlightfirst & {
      &:first-child {
        color: $color__prime;
      }
    }
  }
  // RTE For Careers
  .boldaccentgreen {
    color: $color__green--dk;
  }

  .bolddarkblue {
    color:  $color__prime--dk;
  }

  .darkgrey {
    color: $color__gray--6;
  }

  .button-careers {
    background-color: $color__green--dk;
    border-color:$color__green--dk;
    border: 1px solid;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    padding: 0.25em 1em;
    color: #ffffff;
    &:hover{
      text-decoration: none;
      color: white;
    }
  }
  .boldfont {
    font-weight: 700;
  }

  &--highlightfirst {
    p,
    &__p {
      margin-bottom: $space__paragraph;
      &:first-child {
        color: $color__prime;
        font-size: $fontsize__5;
        @include fontWeight('bold');
      }
    }
  }

  &__areasoffocuslink {

      @include interactiveColor($color__dark, $color__gray--5);

      &#{&} {
          text-decoration: none;
      }
  }

  * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  hr,
  &__hr {
    margin-top:    $space__paragraph;
    margin-bottom: $space__paragraph;
    border: 0;
    border-top: 1px solid gray;
  }

  blockquote,
  &__blockquote {
    @include blockQuote();
  }

  &__disclaimer {
    margin-top: $space__paragraph * 2;
    font-size: $fontsize__7;
    color: $color__gray--6;
  }

  .button {
    text-decoration: none;
  }

  cite {
    font-size: 1rem;
    color: $color__gray--6;
    display: block;

    &:before {
      content: '—';
      margin-right: 1em;
    }

    + p {
      margin-top: $space__paragraph;
    }
  }
  
  &--custom-anchor {
    a,
    &__a {
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.rt,
.richtext {
  ul,
  &__ul,
  ol,
  &__ol {
    margin-bottom: $space__paragraph;
    
    li {
      margin-left: 1.5em;
      margin-bottom: calc($space__paragraph/2);
    }
  }

  ul,
  &__ul {
    li {
      position: relative;
      margin-left: 0;
      padding-left: 1.5em;
      
      &:before {
        content: "\003e";
        position: absolute;  
        left: 0;
      }
    }
  }
  ol,
  &__ol { list-style: decimal; }
  
  em,
  &__em,
  i,
  &__i {
    font-style: italic;
  }

  strong,
  &__strong,
  b,
  &__b {
    @include fontWeight('bold');
  }

  mark,
  &__mark {
    background-color: $color__aqua--lt;
    color: inherit;
  }

  small,
  &__small {
    font-size: $fontsize * .85;
  }

  del,
  &__del,
  s,
  &__s,
  strike,
  &__strike {
    text-decoration: line-through;
  }

  ins,
  &__ins,
  u,
  &__u {
    text-decoration: underline;
  }

  sub,
  &__sub {
    vertical-align: sub;
    font-size: $fontsize * .85;
  }

  sup,
  &__sup {
    vertical-align: super;
    font-size: $fontsize * .85;
  }

  //youtube/iframe resizing
  iframe, object {
    max-width: 100%;
  }

  &--spaced {
      margin-top: 1rem;
  }
  
  &--custom-anchor {
    a,
    &__a {
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.richtext--custom-anchor {
  a,
  &__a {
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
  }
}