﻿.home-carousel {
    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        height: 100%;
    }

    &__info {
        position: relative;
        
        .tile__meta {
            color: $color__white;
            font-size: 1rem;
        }

        .tile__abstract {
            color: $color__white;
            font-size: 1rem;
        }

        .tile__title {
            color: $color__aqua;
            font-size: $fontsize__3;
        }

        .tile__cta {
            color: $color__aqua;
            font-size: $fontsize__5;
        }

        .tile__inner {
            padding-bottom: 3rem;
        }
    }

    &__media-container {
        padding-bottom: 56.25%;
        position: relative;

        &--announcement {
            padding-bottom: 45%;
        }
    }

    @include respondTo('md') {
        &__info {    
            .tile__inner {
                padding-bottom: 0;
                position: absolute;
                top: 0;
                right: 0;
                overflow: hidden;
                height: calc(100% - 3rem);
            }
        }
    }
}
