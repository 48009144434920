@mixin backgroundColor($type: 'dark') {
  @if $type == light {
    background-color: $color__light;
  } @else {
    background-color: $color__dark;
  }
}
@mixin backgroundFull($vertical: center, $horizontal: center) {
  background-size: cover;
  background-position: $vertical $horizontal;
  background-color: transparent;
  background-repeat: no-repeat;
}

@mixin backgroundFullWidth($vertical: center, $horizontal: center) {
  background-size: 100% auto;
  background-position: $vertical $horizontal;
  background-color: transparent;
  background-repeat: no-repeat;
}

@mixin backgroundFullHeight($vertical: center, $horizontal: center) {
  background-size: auto 100%;
  background-position: $vertical $horizontal;
  background-color: transparent;
  background-repeat: no-repeat;
}


@mixin backgroundOverlay($opacity: .3, $color: $color__dark) {
  position: relative;

  > * {
    position: relative;
    z-index: $z__ahead;
  }
  &:before {
    position: absolute;
    z-index: $z__behind;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba($color, $opacity);
    content: "";
  }
}

@mixin backgroundCover($background-size: cover, $gradient-offset: 25vh) {
  @include backgroundFull(top);
  
  background-color: $color__gray--E;
  background-size: $background-size;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z__behind;
  width: 100%;
  min-height: 100vh + $gradient-offset;
  overflow: hidden;

  
  //gradient
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $gradient-offset + 25vh;
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba($color__gray--E-lt, 1));
  }
}
