.callout {
  background-color: $color__bg--dk;
  color: $color__white;

  &__inner {
    padding: $space__tile--mobile;
    padding-bottom: $space__component;
  }

  &__image {
    width: 100%;
    text-align: center;

    > img {
      width: 100%;
    }

    @include no-print;
  }

  @include respond-to-print() {
    display: none;
  }

  &--careers {
    background-color: none;

    .callout__inner {
      background-color: $color__prime;
    }

    .callout__image {
      position: relative;
      
      &--gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #004C97 16.4%, rgba(0, 76, 151, 0) 59.92%);
      }
    }
  }
}