@mixin showhide {
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;

  &--active {
    height: auto;
    width: auto;
    opacity: 1;
    z-index: $z--top;
  }
}