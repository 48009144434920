
.message__404 {
  font-weight: $font__weight--bold;
  font-size: $fontsize__4;
  margin-bottom: 1rem;
  color: $color__prime--lt;
}
.notfound__input {
  max-width: 800px;
  .CoveoSearchInterface .coveo-search-section {
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .CoveoSearchInterface .coveo-main-section {
    max-width: none !important;
  }
  .magic-box-input>input {
    font-size: 1.125rem !important;
    min-height: 1.2em !important;
    padding: .8em !important;
    line-height: 1.2em !important;
    border-radius: 0 !important;
    color: #555 !important;
    font-weight: 700 !important;   
    text-indent: 0 !important;
    font-family: Roboto,Arial,Microsoft Yahei,微软雅黑,STXihei,华文细黑,sans-serif !important;
  }
  .CoveoResultLink {
    text-decoration: none !important;
    color: #000 !important;
    font-weight: 400 !important;
    padding: .25rem 1rem !important;
    text-align: left !important;
    transition: color .4s cubic-bezier(.165,.84,.44,1),background-color .4s cubic-bezier(.165,.84,.44,1) !important;
    background-color: #fff !important;
    line-height: 1.1rem !important;
    display: table !important;
    width: 100% !important;
  }
  .CoveoSearchInterface .coveo-highlight, .CoveoSearchInterface .highlight {
    font-weight: bold !important;
  }
  .coveo-list-layout.CoveoResult.coveo-omnibox-selectable {
    padding: 0 0 0 0 !important;
    border-bottom: none !important;
  }
}
.warning__container {
  display: flex;
  align-items: flex-end;
  font-weight:$font__weight--bold;
  margin-bottom: 50vh;
  width: 100%;
  &_404 {
    color: $color__white;
    line-height: 0.72em;
    font-size: $fontsize__0;
  }
  &_inner {
    width: 75%;
    margin-left: 20px;
    font-size: $fontsize__4;
    &--label {
      color: $color__white;
      margin-bottom: 10px;
    }
  }
  @include respondToReverse('sm') {
      display: block;
      &_404{
        margin-bottom: 1rem;
      }
      &_inner {
        width: 100%;
        margin-left: 0px;
        display: block;
      }
      .CoveoSearchInterface .CoveoSearchbox {
        margin-right: 0px;
      }
  }
  ::-webkit-input-placeholder { 
    color: $color__prime;
    font-weight: $font__weight--bold;
    padding-left: 3px;
  }
}
