.accordion {
  $vspace: $space__sm;

  position: relative;
  display: block;
  border-top: 1px solid $color__gray--D;

  #honors_distinctions {
    p {
      margin-bottom: 8px;
    }
  }

  &__item {
    padding-bottom: $space__sm;
    padding-top: $space__sm;
    border-bottom: 1px solid $color__gray--D;
  }

  &__trigger {
    position: relative;
    display: block;
    width: 100%;
    padding: $vspace $space__lg $vspace 0;
    text-align: left;
    @include fontWeight('bold');
    cursor: pointer;
    text-transform: uppercase;
    
    &--careers {
      color: $color__gray--6;
      text-transform: none;
    }
  
  }

  &__content {
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    visibility: hidden;
    max-height: 0;
    @include transition(max-height .3s ease, margin .3s ease, height .3s ease);

    &--active {
      max-height: none;
      margin-top: $vspace;
      margin-bottom: $vspace;
      visibility: visible;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    height: auto;
    border-radius: 100%;
    color: $color__black;
    text-align: center;
    @include translateY(-50%);
    font-size: $fontsize__8;
  }

  @include respond-to-print {
    &__icon {
      display: none;
    }
    &__trigger {
      padding-top: $space;
    }
    &__content {
      visibility: visible;
      max-height: none;
      height: 100% !important;

      &--active {
        margin-bottom: $space;
      }
    }
  }
}