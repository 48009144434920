.form {
  display: block;

  // Wrapper For Form Inputs
  &__row {
    margin-bottom: $space__paragraph;
  }

  &__col {
    margin-bottom: $space__component;
  }

  @include respondTo('md') {
    &__inner {
      display: flex;
      flex-flow: row nowrap;
    }

    &__col {
      flex: 1 0 auto;
      margin-bottom: 0;
      
      &--alpha {
        flex: 0 0 auto;
        width: 45%;
        padding-right: $space__md;
      }
    }
  }
}

//contact us
.contact-form {
    background-color: $color__bg--dk-md;
    padding: 2rem;

    &__section {

        &--field-row {
            width: 100%;
            margin: 1rem 0;
            display: flex;
            flex-direction: row;
            position: relative;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    .field-validation-error {
        color: $color__red;
        position:absolute;
        left: 0.5rem;
        top: 1.25rem;
        width: 25%;
    }

    &__label {
        color: $color__white;
        order: 0;
        flex-basis: 25%;
    }

    &__input {
        background-color: $color__gray--6;
        border-color: $color__gray--6;
        color: $color__white;
        transition: $transition__interactive;
        padding: .25em 1em;
        font-weight: 700;
        order: 1;
        flex-basis: 75%;

        &--singleline {

        }
        &--email {

        }
        &--telephone {

        }
        &--multiline {
            max-width: 100%;
        }

        &::placeholder {
            color: $color__white;
        }

        &--submit {

        }

        &.input-validation-error {
            border-color: $color__red;
        }
    }

    @include respondToReverse('xl') {
        &__section {
            &--field-row {
                flex-direction: column;
            }
        }

        .field-validation-error {
            left: unset;
            right: 0.5rem;
            top: 0;
            text-align: right;
            width: 75%;
        }
    }

    @include respondToReverse('xt') {
        .field-validation-error {
            display: none;
        }
    }
}