.toolbar {
  padding-top: $space;
  padding-bottom: $space;
  background-color: $color__gray--F;
  
  &--arrows {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__block {
    display: block;

    &--nomobile {
      display: none;
    }
  }

  &--nomobile {
    display: none;
  }

  .sharelist {
    margin-top: 1rem;
  }

  @include respondTo('md') {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &__block {
      display: inline-block;
      flex: 1 0 auto;
      max-width: 75%;

      &--nomobile {
        display: inline-block;
      }
    }

    &__full {
      .toolbar__block {
        max-width: 100%;
      }
    }

    .sharelist {
      margin-top: 0;
    }
  }

  @include respondTo('xl') {
    &__block {
      max-width: 85%;
    }
  }

  @include respond-to-print {
    padding: calc($space/2) $space;
  }
}