.alpha {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  &__item {
    display: inline-block;
  }

  @include respondTo('md') {
    justify-content: space-between;

    .form__col--alpha & {
      justify-content: flex-start;
    }
  }

  @include respondTo('xxl') {

    &__item {
      .form__col--alpha & {
        margin: 0 1px;
      }
    }
    
  
  }
}