.prompt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  padding: 1.5em 1em;
  background-color: $color__info--lt;
  visibility: visible;
  @include translate(0, 100%);
  @include transition( transform 2000ms 600ms $transition-easing, visibility 2000ms 600ms $transition-easing);

  &__instructions {
    text-align: center;
    font-size: rem(14px);
    line-height: 1.6;
  }

  &__buttons {
    text-align: center;
    margin-top: 1.5em;
  }

  &__button {
    margin-left: $space;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &--cancel {
    text-decoration: underline;
    @include interactiveColor($color__black, $color__gray--6);
    cursor: pointer;
    white-space: nowrap;
  }

  .page--ready & {
    @include translate(0, 0);
  }

  &.prompt--hide {
    @include translate(0, 100%);
    visibility: hidden;
  }

  @include respondTo('sm') {
    flex-direction: row;

    &__instructions {
      text-align: left;
      font-size: $fontsize;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 0;
      margin-left: 2em;
      flex-shrink: 0;
      max-width: 30%;
    }
  }

  @include respond-to-print {
    display: none;
  }
}