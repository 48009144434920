.contentcol {
    position: relative;

    &--main {
        @include respondTo('md') {
            width: calc(100% - #{$size__contentside});
        }

        @include respondTo('xxl') {
            width: calc(75% - #{$space__md});
        }

        .contentcol__container--wide & {
            @include respondTo('xl') {
                width: calc(100% - #{$size__contentside--xl});
            }
        }
    }

    &--side {
        margin-top: $space__section;

        @include respondTo('md') {
            width: $size__contentside;
            margin-top: 0;
            padding-left: $space__md;
        }

        @include respondTo('md') {
            width: 25%;
            margin-top: 0;
            padding-left: $space__md;
        }

        @include respondToReverse('md') {            
            margin-left: 2rem;            
        }

        .contentcol__container--wide & {
            @include respondTo('xl') {
                width: $size__contentside--xl;
            }
        }
    }

    &__container {
        width: 100%;
    }

    @include respondTo('md') {
        flex: 1 0 auto;

        &__container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    @include respond-to-print {
        &--side {
            margin-top: $space;
        }
    }
}
