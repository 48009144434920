.stat-component {
  padding: 0 $space $space__xl;
  color: $color__white;
  background-color: $color__prime;

  &--no-background {
    background-color: transparent;
    color: $color__prime;
    padding: $space__xxl 0;
  }

  &--narrow {
    text-align: center;
    align-content: center;
    padding: $space__xxl;
  }

  &__heading {
    font-size: $fontsize__h3;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: rem(13px);
    margin-bottom: $space__md;
  }

  &__tile {
    padding-bottom: $space__lg;

    &-title {
      font-size: $fontsize__h5;
      font-weight: $font__weight--bold;
      padding-bottom: $space;

      &-number {
        font-size: $fontsize__h1;
        padding-right: $space;
        &--narrow {
          padding-right: 0;
        }
      }
      
      &-postfix{
        font-size: $fontsize__h1;
        margin-left: -$space;
        &--narrow{
          margin-left: 0;
        }       
      }
    }
  }

  @include respondTo('md'){
    padding: 0 $space__xxl $space__xxl;

    &--no-background {
      padding: $space__xxl 0;
    }

    &--narrow {
      padding: $space__xxl;
    }

    &__content {
      display: flex;
    }

    &__tile {
      padding-bottom: 0;
      padding-right: $space__md;
      // max-width: rem(341px);
      width: 100%;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}