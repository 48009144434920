.lang {
  padding: $space__sm 0;
  background-color: $color__prime;

  &__link {
    display: block;
    padding: calc($space__sm/2) $space;
    @include interactiveColor($color__light, $color__light);
    text-transform: capitalize;

    &:hover {
      text-decoration: underline;
    }
  }
}