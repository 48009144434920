.dropdownblocks {
  &__trigger {
    @include input();
    @include interactive();
    display: block;
    width: 100%;
    padding: $space__tile--mobile;
    text-transform: uppercase;
    text-align: left;
    font-size: $fontsize__h4;
    @include fontWeight('bold');
  
    &--red { 
      @include inputColor($color__white, $color__red, $color__red, $color__white, $color__red, $color__red);
      &.dropdownblocks__trigger--active { background-color: $color__red; }
    }
    &--blue { 
      @include inputColor($color__white, $color__prime, $color__prime, $color__white, $color__prime, $color__prime);
      &.dropdownblocks__trigger--active { background-color: $color__prime; }
    }
    &--green { 
      @include inputColor($color__white, $color__green, $color__green, $color__white, $color__green, $color__green);
      &.dropdownblocks__trigger--active { background-color: $color__green; }
    }
    &--brown {
      @include inputColor($color__white, $color__brown, $color__brown, $color__white, $color__brown, $color__brown);
      &.dropdownblocks__trigger--active { background-color: $color__brown; }
    }
    &--purple {
      @include inputColor($color__white, $color__plum, $color__plum, $color__white, $color__plum, $color__plum);
      &.dropdownblocks__trigger--active { background-color: $color__plum; }
    }
    &--dynamicColor {
        @include customInputColor($color__white, rgba(255, 255,255, 0.85), $color__plum, $color__plum, $color__plum);
        &.dropdownblocks__trigger--active { opacity: 1 }
    }
  }

  &__decor {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: $space;
    @include translate(0, -50%);
    font-size: $fontsize__5;

    &:before {
      @include icon($icon-plus);
    }
    
    .dropdownblocks__trigger--active & {
      &:before {
        @include icon($icon-minus);
      }
    }

    @include no-print;
  }

  &__content {
    position: relative;
    padding: $space__tile--mobile;
    padding-top: 0;
    background-color: $color__white;
    margin-top: 0.25em;
  }

  &__drop {
    @include transition(max-height .6s ease, height .6s ease, margin .6s ease, visibility .6s ease);
    overflow: hidden;
    
    &--active {
      margin-bottom: -0.5em;
    }

    &:not(.dropdownblocks__drop--active) {
      height: 0;
      visibility: hidden;
    }    
  } 

  @include respondTo('md') {
    
    padding-bottom: $size__tile--sm;

    &__list {
      position: relative;
      padding-bottom: $space__md;
    }

    &__item {
      position: static;
    }

    &__trigger {
      @include input();
      @include interactive();
      display: block;
      width: 100%;
      padding: $space__tile--mobile;
      text-transform: uppercase;
      text-align: left;
      font-size: $fontsize__h4;
      @include fontWeight('bold');
    
      &--red { 
        @include inputColor($color__white, rgba($color__red, 0.85), $color__red, $color__white, $color__red, $color__red);
      }
      &--blue { 
        @include inputColor($color__white, rgba($color__prime, 0.85), $color__prime, $color__white, $color__prime, $color__prime);
      }
      &--green { 
        @include inputColor($color__white, rgba($color__green, 0.85), $color__green, $color__white, $color__green, $color__green);
      }
      &--brown {
        @include inputColor($color__white, rgba($color__brown, 0.85), $color__brown, $color__white, $color__brown, $color__brown);
      }
      &--purple {
        @include inputColor($color__white, rgba($color__plum, 0.85), $color__plum, $color__white, $color__plum, $color__plum);
      }
      &--dynamicColor {
        @include customInputColor($color__white, rgba(255, 255,255, 0.85), $color__plum, $color__plum, $color__plum);
      }
      &:after {
        display: none;
      }
    }

    &__content {
      padding-top: $space__tile--mobile;
      &--red { background-color: $color__red; }
      &--blue { background-color: $color__prime; }
      &--green { background-color: $color__green; }
      &--brown { background-color: $color__brown; }
      &--purple { background-color: $color__plum; }
      margin-top: 0;
    }

    &__trigger {
      min-height: $size__tile--ty;
      font-size: $fontsize__h3;
      overflow: visible;
    }

    &__drop {
      position: absolute;
      top: 100%;
      left: 0;
      
      width: 100%;
    }

    &__decor {
      position: absolute;
      bottom: auto;
      left: 50%;
      right: auto;
      top: 100%;
      z-index: $z__behind;
      @include translate(-50%, 0);
      @include transition(opacity 0.3s ease);
      @include arrow('down', $size * 1.5, $size * 1.25, inherit);

      opacity: 0;

      &:before {
        display: none;
      }

      .dropdownblocks__trigger--active & {
        opacity: 1;
        
        &:before {
          display: none;
        }
      }
    }
  }

  @include respondTo('xl') {
    &__trigger {
      padding: $space__tile;
    }

    &__content {
      padding: $space__tile;
    }
  }

//hacking the css to get it to match the functional spec as the FED did not take into account that the colors can be configured with the cms.
  @include respondToReverse('md') {
    &__content {
        &--dynamicColor {
            background-color: transparent !important;
        }
    }
  }
}