.modal-mask {
    $spacing--disclaimer: $space * 1.5;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s ease;
    -webkit-transform: scale(1);
    transform: scale(1);
    height: 0;

    &.active {
        opacity: 1;
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: table;
        transition: opacity .3s ease;
        -webkit-transform: scale(1);
        transform: scale(1);
        background-color:rgba(0, 0, 0, 0.3);

        .modal-wrapper {
            display: table-cell;
            vertical-align: middle;

            .modal-container {
                width: 75%;
                margin: 0px auto;
                padding: 20px 30px;
                background-color: $color__info--lt;
                border-radius: 16px;
                box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
                transition: all .7s ease;
                @include respondTo('sm') {
                    width: 50%;
                }
                .modal-header h4 {
                    margin-top: 0;
                }
                .modal-body {
                    margin: 20px 0;
                }
                .modal-footer {
                    padding-bottom: 20px;
                    text-align: center;
                }
                .button {
                    margin: 0 5px;
                    width: 95%;
                    &:not(:first-child) {
                        margin-top: 1rem;
                    }

                    @include respondTo('md') {
                        width: auto;

                        &:not(:first-child) {
                            margin-top: 0;
                        }
                    }
                }        
            }
        }
    }
}