.careers-list {
  padding: $space__xl $space;

  &__heading {
    font-size: $fontsize__h2;
    font-weight: $font__weight--bold;

    &--blue {
      color: $color__prime;
    }
  }

  h3 {
    color: $color__gray--3;
    font-size: $fontsize__h3;
    padding-top: $space__xl;
    padding-bottom: $space__md;
    font-weight: $font__weight--bold;
  }

  @include respondTo('md') {
    padding: $space__xl $space__xxl;

    &__heading {
      font-size: $fontsize__careers-desktop;
    }

    &__opportunities,
    &__locations {
      width: 100%;


      h3 {
        font-size: $fontsize__h3;
      }
    }

    &__opportunities {
      padding-right: $space * 4;
      width: 40%;
    }

    .careers-linked-list-container {
      display: flex;
    }
  }
}