.blocklist {
  &__item {
    & + & {
      margin-top: $space__paragraph * 1.5;
    }
  }

  &__title {
    margin-bottom: $space__sm;
    font-size: $fontsize__4;
    @include fontWeight('bold');
  }
}