.grid {

  $space__grid: $space;

  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  
  > * {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &--2-mobile {
    > * {
      margin-left: 0;
      margin-right: 0;
      width: 50%;

      &.grid__item--wide {
        width: 100%;
      }
    }
  }

  &--3-spaced-2mobile,
  &--2-spaced,
  &--3-spaced,
  &--4-spaced {
    > * {
      margin-bottom: $space__grid * 3;
    }
  }

  &--vertspace {
    > * {
      margin-top: $space__paragraph;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @include respondTo('none', 'sm') {
    &--3-spaced-2mobile,
    &--4-spaced {
      > * {
        margin-left: calc($space__grid/2);
        margin-right: calc($space__grid/2);
        width: calc(50% - #{$space__grid});
  
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }

  @include respondTo('ty') {
    &--4 {
      margin: 0;

      > * {
        width: 50%;
      }
    } 
  }

  @include respondTo('none', 'ty') {
      &--4-spaced {
          margin: 0;

          > * {
              width: 100%;
          }
      }
  }

  @include respondTo('sm') {
    &--2-spaced-nomobile,
    &--2-spaced {
      > * {
        margin-left: $space;
        margin-right: $space;
        width: calc(50% - #{$space} - 1px);

        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
    &--4 {
      > * {
        margin: 0;
        width: 33.33%;
      }
    }
  }

  @include respondTo('sm', 'md') {
    &--3-spaced-2mobile,
    &--3-spaced {
      > * {
        margin-left: calc($space__grid/2);
        margin-right: calc($space__grid/2);
        margin-bottom: $space__grid;
        width: calc(50% - #{$space__grid});
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }

    &--4-spaced {
      $spc3: ($space__grid*1.5); 

      > * {
        width: calc(33.33% - #{$spc3});
        margin-left: calc($spc3 / 2);
        margin-right: calc($spc3 / 2);

        &:nth-child(3n+1) {
          margin-left: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  @include respondTo('sm', 'lg') {
    &--4 {
      > * {
        margin: 0;
        width: 33.33%;
      }
    }
  }

  @include respondTo('md') {
    &--2 {
      > * {
        margin-left: 0;
        margin-right: 0;
        width: 50%;

        &.grid__item--wide {
          width: 100%;
        }
      }
    }

    &--3 {
      > * {
        margin-left: 0;
        margin-right: 0;
        width: 33.3333%;

        &.grid__item--wide {
          width: 66.6666%;
        }
      }
    }

    &--3-spaced-2mobile,
    &--3-spaced {
      $spc3: ($space__grid * 1.5); 

      > * {
        width: calc(33.33% - #{$spc3});
        margin-left: calc($spc3 / 2);
        margin-right: calc($spc3 / 2);

        &:nth-child(3n+1) {
          margin-left: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    &--5-spaced-inline {
      flex-flow: row nowrap;
      $spc5: calc($space__grid / 2);

      > * {
        width: calc(20% - #{$spc5});
        margin-left: calc($spc5 / 1.6);
        margin-right: calc($spc5 / 1.6);

        &:nth-child(5n+1) {
          margin-left: 0;
        }

        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  
    &--4-spaced {
      > * {
        margin-left: calc($space__grid/2);
        margin-right: calc($space__grid/2);
        width: calc(25% - #{$space__grid});

        &:nth-child(4n+1) {
          margin-left: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  &--yt-loading {
    width: 100%;
    height: rem(225px);
    animation-name: loading-flash;
    animation-duration: 3s;
    animation-iteration-count: infinite;

    &-lg {
        height: rem(320px)
    }
  }

  @include respondTo('lg') {
    &--4 {
      > * {
        margin: 0;
        width: 25%;
      }
    }
  }
  @include respondTo('xl') {
    &--5-spaced-inline {
      $spc5: ($space__grid * 1.25);

      > * {
        width: calc(20% - #{$spc5});
        margin-left: calc($spc5 / 1.6);
        margin-right: calc($spc5 / 1.6);

        &:nth-child(5n+1) {
          margin-left: 0;
        }

        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }

  @include respond-to-print {
    display: block;
    
    &--3-spaced-2mobile,
    &--2-spaced,
    &--3-spaced,
    &--4-spaced {
      > * {
        margin-bottom: $space__grid;
      }
    }
  }

}

@keyframes loading-flash {
    0%  { background-color: white; }
    50% { background-color: grey; }
    100% { background-color: white; }
}