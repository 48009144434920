.breadcrumbs-arrows {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -$space__xl;

    &__item {
        float: left;
    }
    
    &__text {
        color: $color__white;
        text-decoration: none;
        background: $color__prime;
        padding: $space 0 $space 65px;
        position: relative;
        display: block;
        float: left;
        transition: 0.5s;

        &:after {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            margin-top: -25px;
            border-left: 30px solid $color__prime;
            position: absolute;
            top: 50%;
            left: 100%;
            z-index: 2;
            @include respondTo('sm') {
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                margin-top: -25px;
            
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                margin-top: -50px;
            }
        }
        &:before {
            content: " ";
            display: block;
            width: 0;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            margin-top: -25px;           
            height: 0;
            border-left: 30px solid white;
            position: absolute;
            top: 50%;
            margin-left: 1px;
            left: 100%;
            z-index: 1;
            @include respondTo('sm') {
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
                margin-top: -50px;
            }
        }
    }

    &__link {
        color: $color__prime;
        background: $color__gray--D;
        &:after {
            border-left: 30px solid $color__gray--D;
        }
    }
} 
