﻿.lawyer-search {
    .keyword-search {
        position: relative;

        &__button {
            position: absolute;
            right: 10px;
            top: 6px;
            height: auto;
            width: auto;
            border: none;

            svg {
                color: $color__white;
            }
        }
    }

    .magic-box-clear {
        right: 20px;
        top: 0;
    }
}
