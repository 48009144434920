.banner {
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0 20px 70px 10px rgba($color__black, 0.3);
  background-color: $color__dark;

  &--pulltop {
    transform: translateY(-33%);
  }
  
  &__inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &--tall {
      min-height: $size__hero;
    }
  }
}