.hero {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $color__prime;

  min-height: calc($size__hero--mobile / 1.5);
  height: calc($size__hero--mobile / 5);

  &--short {
    min-height: calc($size__hero--mobile/2);
    height: calc($size__hero--mobile/2);
  }

  &--tall {
    min-height: $size__hero--mobile;
    height: $size__hero--mobile;
  }

  &--home {
      max-height: calc(50vh - 3.75rem);
      min-height: 5rem;
      height: 15rem;
  }

  &--full {
    height: auto;
  }

  &--transparent {
    background-color: rgba($color__prime, 0);
  }

  &__inner {
    position: relative;
    z-index: $z__base;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    height: inherit;
    min-height: inherit;
    margin-top: 0;
    padding-top: $size__header--mobile;
    // padding-bottom: $space * 1.5;
    padding-bottom: 0;

    &--full {
      padding-top: 0;
      padding-bottom: 0;
    }

    .hero--tall &,
    .hero--home & {
      justify-content: center;
      padding-bottom: $space__section;
    }
  }

  &__row {
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    
    &--inline {
      @include clearfix();
      display: block;

      > * {
        display: block;
        margin-top: $space__sm;
      }
    }

    .sideaddress__row {
      color: $color__white;
      a { color: $color__white; }
    }

    @include respondTo('md') {
      &--inline {
        > * {
          &:last-child {
            float: right;
            margin-top: 0;
          }
        }
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z__behind;
    @include backgroundFull(center, center);
    // background-color: $color__prime;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $z__ahead;
      box-shadow: inset 0 0 100px 60px rgba($color__prime, 0.75);
    }
    &--noshadow {
      &:before {
        box-shadow: none;
      }
    }

    &--flat {
      &:before {
        background-color: rgba($color__prime, 0.75);
        box-shadow: none;
      }
    }
  }

  &__titlerow {
    display: block;
  }

  &__titleitems {
    display: inline-block;
    position: relative;
  }

  &__titleitem {
    display: inline-block;
    position: relative;
  }

  &__title {
    color: $color__light;
    text-transform: uppercase;
    font-size: $fontsize__h2;
    @include fontWeight('bold');
    vertical-align: middle;
    max-width: 100%;
    
    &--inline {
      display: inline-block;
    }

    &--small {
        font-size: $fontsize__h2 *.75;
    }
  }

  &__suptitle {
    font-size: $fontsize__h3;
    color: $color__white;
    @include fontWeight('bold');
  }

  &__subtitle {
    //$vspace: $space__paragraph, $hspace, $quotespace, $fs, $colortext
    border: none;
    font-size: $fontsize;
    color: $color__white;
    @include fontWeight('bold');
    margin-bottom: $space;
  }

  &__location {
    margin-top: $space__component;
    margin-bottom: $space__component;
  }

  &__meta {
    display: inline-block;
    color: $color__white;
    margin-left: $space;
    vertical-align: middle;

    &--nomobile {
      display: none;
    }
  }

  &__metatitle {
    display: none;
    margin-right: $space__ty;
  }

  &__phone {
    color: $color__white;
    @include fontWeight('bold');
  }

  &__link {
    @include interactiveColor($color__white, $color__prime--lt);
  }

  &__icon {
    font-size: $fontsize__4;
  }

  &__floatbutton {
    display: none;
    position: absolute;
    right: $space__md;
    bottom: $space__md;
  }

  &__link {
    display: none;
  }

  @include respondTo('sm') {
    min-height: $size__hero;
    height: $size__hero;

    &--tall {
      height: auto;
    }

    &--home {
      max-height: calc(50vh - 3.75rem);
      min-height: 5rem;
      height: 15rem;
    }

    &--short {
      min-height: calc($size__hero--mobile/2);
      height: calc($size__hero--mobile/2);
    }
  }

  @include respondTo('md') {
    min-height: $size__hero;
    height: $size__hero;

    &--md {
      min-height: $size__hero--md;
      height: $size__hero--md;
    }

    &--full {
      height: $size__hero;
    }

    &--tall,
    &--home {
      min-height: $size__hero + ($size__hero * .58);
    }

    &__floatbutton {
      display: inline-block;
    }

    &__inner {
      margin-top: -$size__header;
      padding-top: $size__header;

      &--full,
      &--home {
        padding-top: 0;
        padding-bottom: 0;
      }

      .hero--tall &,
      .hero--home & {
        padding-bottom: $space__section;
      }
    }

    &__row {
      &--inline {
        &:after { content: none; display: none; }
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
  
        > * {
          float: none;
          flex: 0 0 auto;

          &:last-child {
            float: none;
          }
          &:after {
            content: '';
            content: none;
          }
        }
      }
    }

    &__title {
      font-size: $fontsize__h1 * .875;

      &--small {
          font-size: $fontsize__h1 * .625;
      }
    }

    &__subtitle {
      max-width: 70%;
    }

    &__metatitle {
      display: inline-block;
    }

    &__meta {
      &--nomobile {
        display: block;
      }
    }
    
    &__icon {
      font-size: $fontsize__3--lg;
    }  
  }

  @include respondTo('lg') {
    &--tall,
    &--home {
      min-height: $size__hero + ($size__hero * .68);
    }

    &__title {
      font-size: $fontsize__h1;

      &--small {
          font-size: $fontsize__h1 * .75;
      }
    }

    &__subtitle {
      max-width: 60%;
    }
  }

  @include respondTo('xxl') {
    &--tall,
    &--home {
      min-height: rem(800px);
    }
  }

  @include respond-to-print() {
    &,
    &--tall,
    &--home {
      min-height: 0;
      height: auto;
    }

    &__image {
      &:before {
        display: none;
      }
    }

    &__inner {
      &,
      .hero--tall & {
        padding: $space;
        padding-top: 0;
      }
    }
  }
}