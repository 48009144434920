$fontsize:    ($base * 1px) !default;

$fontsize__0: rem(112px) !default;
$fontsize__1: rem(64px) !default;
$fontsize__2: rem(32px) !default;
$fontsize__3--lg: rem(30px) !default;
$fontsize__3: rem(24px) !default;
$fontsize__4: rem(20px) !default;
$fontsize__5: rem(18px) !default;
$fontsize__6: rem(14px) !default;
$fontsize__7: rem(12px) !default;
$fontsize__8: rem(11px) !default;

$fontsize__careers-desktop: rem(48px) !default;

$font__weight--light: 300;
$font__weight--normal: 400;
$font__weight--bold: 700;

$fontsize__h1: $fontsize__1 !default;
$fontsize__h2: $fontsize__2 !default;
$fontsize__h3: $fontsize__3 !default;
$fontsize__h4: $fontsize__4 !default;
$fontsize__h5: $fontsize__5 !default;
$fontsize__h6: $fontsize__6 !default;

$font__decor--link: underline;
$font__decor--link-hover: none;

$fontsize__btn: $fontsize__5 !default;

$fontsize__btn--sm: $fontsize__6 !default;

$fontsize__btn--lg: $fontsize__4 !default;


$font__family--serif: "Times", Georgia, "Microsoft YaHei", "微软雅黑", STXihei, "华文细黑", serif;
$font__family--sans-serif: "Roboto", Arial, "Microsoft Yahei","微软雅黑", STXihei, "华文细黑", sans-serif;
$font__family--legacy: Verdana, Arial, Helvetica, sans-serif;

$fontFamilies: (
    sans-serif: $font__family--sans-serif,
    serif: $font__family--serif,
    legacy: $font__family--legacy
);

@mixin fontFamily($family) {
    @if map-has-key($fontFamilies, $family) {
        font-family: map-get($fontFamilies, $family);
    }
}

@mixin fontFamilyImportant($family) {
    @if map-has-key($fontFamilies, $family) {
        font-family: map-get($fontFamilies, $family) !important;
    }
}

$fontWeights: (
    light: $font__weight--light,
    normal: $font__weight--normal,
    bold: $font__weight--bold
);

@mixin fontWeight($weight) {
    @if map-has-key($fontWeights, $weight) {
        font-weight: map-get($fontWeights, $weight);
    }
}

