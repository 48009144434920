.brand {
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 0 0;
  height: 100%;

  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  &__title,
  &__tagtitle {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -9999px;
    z-index: -1;
  }

  &__tagline-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-transform: uppercase;
  }

  &__tagline-intro {
    color: $color__gray--C;
    font-weight: bold;
    font-size: 62px;
    line-height: 52px;
    letter-spacing: -4px;
    margin-right: 6px;
  }

  &__tagline-subtitle {
    font-size: 28px;
    line-height: 25px;
    letter-spacing: -1px;
  }

  &__tagline-first {
    color: $color__white;
  }

  &__tagline-second {
    color: $color__yellow
  }

  &__image {
    aspect-ratio: auto 65/45;
    max-width: none;
    height: calc(#{$size__header--mobile} - #{$space__sm * 2});

    &--dk {
      display: none;
    }
  }

  &__tagline {
    opacity: 0;
    @include translate(50%, 0);
    @include transition( transform 2000ms 600ms $transition-easing, opacity 2000ms 600ms $transition-easing);
    
    .page--ready & {
      opacity: 1;
      @include translate(0, 0);
    }
  }

  &__taglinesvg {
    max-width: 100%;
    -webkit-filter: drop-shadow(0 2px 4px rgba(0,0,0,0.5));      
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.5));  
  }

  @include respondTo('ty') {
    &__tagline-intro {
      font-size: 96px;
      line-height: 76px;
      letter-spacing: -6px;
      margin-right: 8px;
    }
  
    &__tagline-subtitle {
      font-size: 42px;
      line-height: 38px;
      letter-spacing: -2px;
    }
  }

  @include respondTo('sm') {
    &__tagline-intro {
      font-size: 156px;
      line-height: 129px;
      letter-spacing: -8px;
      margin-right: 15px;
    }
  
    &__tagline-subtitle {
      font-size: 68px;
      line-height: 63px;
      letter-spacing: -2px;
    }
  }

  @include respondTo('md') {
    display: block;
    height: auto;
    margin-bottom: $space__md;

    &__content,
    &__link {
      display: block;
    }

    &__image {
      aspect-ratio: auto 3/2;
      width: 75%;
      max-height: none;
      height: auto;
    }

    &__taglinesvg {
      display: none;
    }

    &__tagline-group {
      display: none;
    }
  }

  &__taglinesvg {
    @include no-print;
  }

  @include respond-to-print () {

    &__image {
      display: none;
      &--dk {
        display: block;
      }
    }

    &__tagtitle {
      position: relative;
      top: 0;
      left: 0;
      margin-left: 0;
      z-index: 1;
      text-transform: uppercase;
      font-size: $fontsize__h2;
      @include fontWeight('bold');
      vertical-align: middle;
    }
  }
  
}