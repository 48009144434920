.container {
  @include container__fixed();

  &__spaced {
    @include container__main( $grid__gutter);
    @include respondTo('md') {
      margin-left: $size__sidebar--md;
    }
    @include respondTo('lg') {
      margin-left: $size__sidebar--lg;
    }
    @include respondTo('xl') {
      margin-left: $size__sidebar--xl;
    }
    @include respondTo('xxl') {
      margin-left: $size__sidebar--xxl;
    }
  }

  &__limit {
    @include container__limit();
  }

  &__fluid {
    @include container__fluid();
  }
}