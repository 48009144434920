.tile {
  overflow: hidden;
  position: relative;
  min-height: $size__tile--ty;
  background-color: $color__prime;
  border-color: $color__prime;
  @include interactiveColor($color__white, $color__white);
  @include transition(background-color .6s ease, border-color .6s ease);

  &--title {
    min-height: auto;
  }

  &--shorter {
    min-height: $size__tile--xt;
  }

  &:not(.tile--nohover) {
    &:hover {
      background-color: $color__prime--dk;
      border-color: $color__prime--dk;
    }
  }

  &__badge {
    position: absolute;
    right: $space;
    top: $space;
    font-size: $fontsize__4;
  }

  &__link {
    color: inherit;
    height: 100%;
    
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z__behind;
    width: 100%;
    height: 100%;
    @include backgroundFull();
    @include scale(1);
    @include transition(transform .8s ease);

    &--professional {
        background-size: contain;
        background-position: right;
    }

    .tile:hover & {
      @include scale(1.15);
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $z__behind;
      background-color: rgba($color__prime, .5);
    }

    .tile__list--basic & {
      &:before {
        background-color: rgba($color__black, .5);
      }
    }
  }

  &__inner {
    position: relative;
    z-index: $z__base;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    text-decoration: inherit;
    padding: $space__tile--mobile;

    &--center {
      justify-content: center;
    }

    &--narrow {
      padding-left: 50%;
    }
  }

  &__linklist-item {
    display: inline-block;
    text-transform: uppercase;

    &:after {
      display: inline-block;
      content: "|";
      margin-left: $space__sm;
      margin-right: $space__sm;
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }

  &__linklist-link {
    display: inline-block;
    color: $color__white;
    font-size: $fontsize__6;

    &:hover {
      text-decoration: none;
      color: $color__aqua;
    }
  }
  
  &__row {
    flex: 0 0 auto;
    width: 100%;
    position: static; //changing this will impact placement

    &--flex {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      > * {
        flex: 1 1;

        &:last-child {
          flex: 0 0 auto;
          padding-left: $space__sm;
        }
      }
    }


    & + & {
      margin-top: $space__paragraph;

      &--short {
        margin-top: $space__ty;
      }
    }
  }

  &__title {
    font-size: $fontsize__6;
    @include fontWeight('bold');
    line-height: 1.4em;
    overflow: hidden;
    max-height: 80%;

    &--big {
      font-size: $fontsize__5;
    }

    &--lg  {
      font-size: $fontsize__3;
    }

    &--up {
      text-transform: uppercase;
    }

    &--em {
      em {
        color: $color__aqua;
      }
    }

    &--em-alt {
      em {
        color: $color__prime;
      }
    }
  }

  &__meta,
  &__cta {
    color: inherit;
    @include fontWeight('bold');

    .tile:hover & {
      color: inherit;
    }
  }

  &__cta {    
    font-size: $fontsize__6;
  }

  &__meta {
    display: inline-block;
    text-transform: uppercase;
    font-size: $fontsize__7;

    /*
    &:after {
      display: inline-block;
      content: "|";
      margin-left: $space__sm;
      margin-right: $space__sm;
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
    */

    & + .tile__meta {
      &:before {
        display: inline-block;
        content: "|";
        margin-left: .333em;
        margin-right: $space__sm;
      }
    }
  }

  .tile__list--2 & {
    @include respondTo('none', 'md') {
      &:nth-child(even) {
        background-color: $color__second;

        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
    @include respondTo('md') {
      &:nth-child(4n-1),
      &:nth-child(4n-2) {
        background-color: $color__second;
        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
  }

  .tile__list--home & {
    @include respondTo('none', 'ty') {
      //alternating stacked tiles
      &:nth-child(even) {
        background-color: $color__second;

        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
    @include respondTo('ty', 'sm') {
      //alternating checker tiles 2 across
      &:nth-child(4n),
      &:nth-child(4n+1) {
        background-color: $color__second;
        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
    @include respondTo('sm') {
      &:nth-child(even) {
        background-color: $color__second;
        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
  }

  .tile__list & {
    @include respondTo('none', 'ty') {
      //alternating stacked tiles
      &:nth-child(odd) {
        background-color: $color__second;

        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
    @include respondTo('ty', 'sm') {
      //alternating checker tiles 2 across
      &:nth-child(4n),
      &:nth-child(4n+1) {
        background-color: $color__second;
        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
    @include respondTo('sm', 'lg') {
      &:nth-child(odd) {
        background-color: $color__second;
        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
    @include respondTo('lg') {
      //alternating checker tiles 4 across checkerboard
      &:nth-child(8n),
      &:nth-child(8n-2),
      &:nth-child(8n-5),
      &:nth-child(8n-7) {
        background-color: $color__second;

        &:not(.tile--img) {
          @include interactiveColorTwo($color__gray--3, $color__gray--3);
        }

        &:not(.tile--nohover) {
          &:hover {
            background-color: $color__aqua;
          }
        }
      }
    }
  }

  .tile__list--rev & {
    &:nth-child(even) {
      background-color: $color__second;
      &:not(.tile--img) {
        @include interactiveColorTwo($color__gray--3, $color__white);
      }

      &:not(.tile--nohover) {
        &:hover {
          background-color: $color__white;
        }
      }
    }
  }

  .tile__list--alt & {
    &--title {
      background-color: $color__aqua;
      @include interactiveColor($color__black, $color__black);
    }
  }

  .tile__list--basic & {
    background-color: $color__black;
    @include interactiveColor($color__white, rgba($color__white, 85%));

    &:not(.tile--nohover) {
      &:hover {
        background-color: $color__black;
      }
    }
  }

  @include respondToReverse('sm') { 
    &__meta {
      display: block;

      & + .tile__meta {
        &:before {
          display: none;
        }
      }
    }
  }  

  @include respondTo('sm') {

    &__title {
      font-size: $fontsize__5;
    
      &--lg  {
        font-size: $fontsize__3;
      }
    }

    &__cta {
      display: inline-block;
    }
  }

  @include respondTo('md') {

    min-height: $size__tile--sm;

    &--shorter {
      min-height: $size__tile--xt;
    }

    &__inner {
      padding: $space__tile--mobile;

      &--narrow {
        padding-left: 20%;
      }
    }

    &__title {
      overflow: visible;
      max-height: none;

      &--featured {
        font-size: $fontsize__4;
      }
      &--big {
        font-size: $fontsize__3--lg;
      }
    }

    &__description {
      display: block;
    }


    &__meta {
      &--date {
        display: inline-block;
      }
      &--large {
        font-size: $fontsize__h5;
      }
    }

    &--title {
      min-height: auto;
    }

    &__two-thirds {
      width: calc(100% * 2/3);
    }
  }

  @include respondTo('lg') {
    &__inner {
      padding: $space__tile--mobile;

      &--narrow {
        padding-left: 40%;
      }
    }
  }

  @include respond-to-print() {
    min-height: 0;

    &--title {
      min-height: 0;
    }

    &__description {
      display: block;
    }

    &__media {
      &:before {
        display: none;
      }
    }

    &__inner {
      &--narrow {
        padding-left: $space__tile--mobile;
      }
    }
  }
}