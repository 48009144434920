$color__black:    #000;
$color__white:    #FFF;
$color__gray--1: #111;
$color__gray--3: #333333;
$color__gray--4: #444444;
$color__gray--5: #555555;
$color__gray--6:  #6F6F6F;
$color__gray--8: #878787;
$color__gray--9: #979797;
$color__gray--A: #A4A4A4;
$color__gray--C:  #C8C8C8;
$color__gray--D:  #DDD;
$color__gray--E: #E0E0E0;
$color__gray--F: #F1F1F1;
$color__gray--F6: #F6F6F6;
$color__gray--F2: #F2F2F2;
$color__gray--4F: #4F4F4F;
$color__gray--82: #828282;

$color__gray-blue: #5D6770;


/*******************************/


//STANDARD COLORS
$color__prime: #004C97;
$color__prime--dk: #003b75;
$color__prime--lt: #A0C0FF;

$color__second: #3EB1C8;
$color__second--dk: #339cb1;
$color__second--lt: lighten($color__second, 35%);

$color__tan: darken(#9D8F79, 20%);
$color__tan--dk: #635113;
$color__tan--lt: lighten($color__tan, 35%);

$color__brown: #a65523;

$color__aqua: #9BD3DD;
$color__aqua--dk: #82C8D4;
$color__aqua--lt: lighten($color__aqua, 15%);

$color__lime: #C4D600;
$color__lime--dk: #95A300;
$color__lime--lt: lighten($color__lime, 35%);

$color__green: darken(#5bb929, 13%);
$color__yellow: #F1B200;
$color__green--dk: darken(#608A00, 5%);
$color__green--lt: lighten($color__green, 35%);

$color__orange: #E87722;
$color__orange--dk: #C35F14;
$color__orange--lt: lighten($color__orange, 35%);

$color__red: #DA291C;
$color__red--dk: #AD2116;
$color__red--lt: lighten($color__red, 35%);

$color__plum: #3f2a56;
$color__plum--dk: darken($color__plum, 35%);
$color__plum--lt: #583b78;

 

/*******************************/

$color__success: #5cb85c;
$color__info:    $color__aqua--dk;
$color__warning: #f0ad4e;
$color__danger:  #d9534f;
$color__success--lt: lighten($color__success, 35%);
$color__info--lt:    $color__aqua--lt;
$color__warning--lt: lighten($color__warning, 35%);
$color__danger--lt:  lighten($color__danger, 35%);

$color__dark: $color__black;
$color__dark--lt: $color__gray--6;
$color__light: $color__white;

$color__text:  $color__dark;
$color__link: $color__prime;

$color__border: $color__gray--9;

$color__input--tx: $color__text;
$color__input--bg: $color__prime--dk;
$color__input--br: $color__prime--dk;

$color__select--tx: $color__text;
$color__select--bg: $color__light;
$color__select--br: $color__dark;

$color__bg: $color__light;
$color__bg--dk: $color__dark;
$color__bg--dk-2: $color__gray--1;
$color__bg--dk-md: $color__gray--4;
$color__bg--lt: $color__gray--F6;
$color__bg--md: $color__gray--F;

$color__social--rss: #f26522;
$color__social--facebook: #3b5998;
$color__social--twitter: #1da1f2;
$color__social--youtube: #ff0000;
$color__social--instagrammagenta: #c32aa3;
$color__social--instagramblue: #4c5fd7;
$color__social--instagrampurple: #7232bd;
$color__social--instagramorange: #f46f30;
$color__social--instagramyellow: #ffdc7d;
$color__social--googleblue: #4285f4;
$color__social--googlered: #ea4335; 
$color__social--googleyellow: #fbbc05; 
$color__social--googlegreen: #34a853;
$color__social--pinterest: #bd081c;
$color__social--googleplus: #db4437;
$color__social--linkedin: #007bb5;
$color__social--vimeoblue: #1ab7ea;
$color__social--tumblr: #2c4762;
$color__social--snapchat: #fffc00;
$color__social--whatsappgreen: #25d366;
$color__social--whatsappteal1: #075e54;
$color__social--whatsappteal2: #128c7e;
$color__social--apple: #a6b1b7;
$color__social--amazon: #ff9900;
$color__social--microsoftred: #f35022;
$color__social--microsoftgreen: #80bb03;
$color__social--microsoftblue: #03a5f0;
$color__social--microsoftyellow: #ffb903;
$color__social--periscope: #40a4c4;
$color__social--foursquarepink: #f94877; 
$color__social--foursquarenavy: #073282;
$color__social--foursquareblue: #2d5be3; 
$color__social--swarm: #ffa633;
$color__social--medium: #02b875;
$color__social--skypeblue: #00aff0;
$color__social--skypedkblue: #0078d7;
$color__social--android: #a4c639;
$color__social--stumbleupon: #e94826;
$color__social--flickrpink: #f40083;
$color__social--flickrblue: #006add;
$color__social--yahoo: #430297;
$color__social--soundcloud: #ff5500;
$color__social--spotifygreen: #1ed760;
$color__social--spotifydarkgreen: #1db954;
$color__social--dribbble: #ea4c89;
$color__social--slackpurple: #4d394b; 
$color__social--slackskyblue: #70cadb; 
$color__social--slackmosss: #3eb890; 
$color__social--slackpink: #e01765; 
$color__social--slackyellow: #e8a723; 
$color__social--slackgreenblue: #1a937d;
$color__social--slackviolet: #472a49; 
$color__social--slackolive: #65863a; 
$color__social--slackred: #cc2027; 
$color__social--reddit: #ff5700;
$color__social--deviantart: #05cc47;
$color__social--pocket: #ee4056;
$color__social--quora: #aa2200;
$color__social--quorablue: #2b6dad;
$color__social--slideshareorange: #e68523;
$color__social--slideshareblue: #00a0dc;
$color__social--fivehundredpx: #0099e5;
$color__social--vk: #4a76a8;
$color__social--listlyorange: #df6d46;
$color__social--listlyblue: #52b1b3;
$color__social--vine: #00b489;
$color__social--steam: #171a21;
$color__social--discord: #7289da;