.experience-editor {
    .component {
        padding-top: 15px;
        margin: 25px 5px;
        border: 1px solid green;
    }

    .contentcol--main ~ .scEmptyPlaceholder {
        width: 25%;
    }

    pagenav {
        display: none;
    }
}