@mixin container__fluid($gutter: $grid__gutter, $gutter--mob: $grid__gutter) {

  $left--mob: calc($gutter--mob / 2);
  $right--mob: calc($gutter--mob / 2);

  $left:  calc($gutter / 2);
  $right: calc($gutter / 2);

  margin-right: auto;
  margin-left: auto;
  padding-left:  $left--mob;
  padding-right: $right--mob;

  @include respondTo('sm') {
    padding-left:  $left;
    padding-right: $right;
  }
}

@mixin container__fluid--nomobile($gutter: $grid__gutter, $gutter--mob: $grid__gutter) {

  $left:  calc($gutter / 2);
  $right: calc($gutter / 2);

  margin-right: auto;
  margin-left: auto;
  
  @include respondTo('sm') {
    padding-left:  $left;
    padding-right: $right;
  }
}

@mixin container__fixed($gutter: $grid__gutter) {
  @include container__fluid($gutter);
  @include respondTo('sm') {
    width: $size__container--sm;
  }
  @include respondTo('md') {
    width: $size__container--md;
  }
  @include respondTo('lg') {
    width: $size__container--lg;
  }
  @include respondTo('xl') {
    width: $size__container--xl;
  }
}

@mixin container__sidebar() {
  @include respondTo('md') {
    width: $size__sidebar--md;
  }
  @include respondTo('lg') {
    width: $size__sidebar--lg;
  }
  @include respondTo('xl') {
    width: $size__sidebar--xl;
  }
  @include respondTo('xxl') {
    width: $size__sidebar--xxl;
  }
}

@mixin container__main($gutter: 0, $gutter--mob: 0) {
  @include container__fluid($gutter, $gutter--mob: 0);

  @include respondTo('md') {
    margin-left: 0;
    max-width: calc(100% - #{$size__sidebar--md});
  }
  @include respondTo('lg') {
    max-width: calc(100% - #{$size__sidebar--lg});
  }
  @include respondTo('xl') {
    max-width: calc(100% - #{$size__sidebar--xl});
  }
  @include respondTo('xxl') {
    max-width: calc(100% - #{$size__sidebar--xxl});
  }
}

@mixin container__limit($gutter: $grid__gutter) {
  @include container__fluid($gutter);

  @include respondTo('md') {
    max-width: $size__container--sm;
  }
  @include respondTo('lg') {
    max-width: $size__container--md;
  }
  @include respondTo('xl') {
    max-width: $size__container--lg;
  }
  @include respondTo('xxl') {
    max-width: $size__container--xl;
  }
}