.body {
  &__inner {
    position: relative;
    margin: 0;

    @include respondTo('md') {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      > * {
        flex: 0 1 auto;
        width: 100%;
  
        &:first-child {
          flex: 1 0 auto;
        }
      }
    }
  }
}