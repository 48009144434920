.sharelist {
  position: relative;
  width: auto;
  height: auto;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  @include transition(margin .6s ease);

  &--active {
    margin-right: $space__xl;
  }

  &__block {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
  }

  &__more {
    position: absolute;
    top: 50%;
    left: calc(100% + #{$space});
    z-index: $z__behind;
    display: inline-block;
    overflow: hidden;
    @include translateY(-50%);
    width: 0;
    padding: 0;
    visibility: hidden;
    @include transition(width .6s ease, visibility .6s ease);

    &--active {
      width: auto;
      visibility: visible;
    }
  }

  &__item {
    display: inline-block;
    margin-left: $space__ty;
    margin-right: $space__ty;

    font-size: $fontsize__4;
    
    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    font-size: $fontsize__6;
    text-transform: uppercase;
    color: $color__gray--5;
    margin-right: $space__ty;
  }

  &__trigger {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-color: rgba($color__light, 0);
    @include interactiveColor($color__gray--3, $color__gray--6);

    &--linkedin {
      top: -2px;
    }

    &--active {
      color: $color__prime;
    }
  }

  @include respondTo('md') {
    display: flex;
  }
}