$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "/assets/Main/public/fonts/icomoon/fonts" !default;

$icon-linkedin-blue: "\e900";
$icon-x-logo: "\e91f";
$icon-clock-icon: "\e91e";
$icon-quote: "\e91d";
$icon-youtubenew: "\e901";
$icon-external-link: "\e91c";
$icon-menu: "\e916";
$icon-print: "\e915";
$icon-bookmark: "\e90e";
$icon-download: "\e90f";
$icon-chevron-right: "\e910";
$icon-chevron-left: "\e911";
$icon-chevron-down: "\e912";
$icon-chevron-up: "\e913";
$icon-select-arrows: "\e914";
$icon-search: "\e902";
$icon-phone: "\e919";
$icon-play: "\e918";
$icon-pause: "\e917";
$icon-player: "\e917";
$icon-close: "\e903";
$icon-minus: "\e904";
$icon-plus: "\e905";
$icon-pin: "\e906";
$icon-linkedin: "\e907";
$icon-feed: "\e908";
$icon-twitter: "\e909";
$icon-facebook: "\e90a";
$icon-google-plus: "\e90b";
$icon-share: "\e90c";
$icon-sphere: "\e90d";
$icon-ellipsis: "\e91b";
$icon-address-card: "\e91a";
$icon-envelope: "\e920";
$icon-youtube: "\e921";

