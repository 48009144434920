/// Helper mixin to size elements
/// @param {Number} $width - Width
/// @param {Number} $height ($width) - Height
@mixin squareSize($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin fullheight() {
  min-height: 100%;
  height: 100%;
}

@mixin placeholderStyle() {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

@mixin blockQuote ($vspace: $space__paragraph, $hspace: $space, $quotespace: .05em, $fs: $fontsize__3, $colortext: $color__text) {
  position: relative;
  
  color:  $color__prime;
  margin: 0  0 $vspace 0;  
  padding: $vspace $hspace;
  padding-bottom: 0;  
  padding-top: 0;
  font-size: $fontsize__3;
  line-height: 1.15em;
  @include fontWeight('bold');
  text-align: left;

  &:before,
  &:after {
    display: inline;
  }

  &:before {
    content: "“";
    margin-right: $quotespace;
  }
  &:after {
    content: "”";
    margin-left: $quotespace;
  }
}

@mixin proportionHeroHeight($height: rem(320px), $height--mobile: rem(400px)) {
  
  $screen__md:  (rem($screen--md) - $size__sidebar);
  $screen__lg:  (rem($screen--lg) - $size__sidebar);
  $screen__xl:  (rem($screen--xl) - $size__sidebar);
  $screen__xxl: (rem($screen--xxl) - $size__sidebar);

  $percent: $height / $screen__xl;

  height: $height--mobile;

  &--full {
    height: auto;
    min-height: $height--mobile;
  }

  @include respondTo('sm') {
    height: $height;
  }

  @include respondTo('md') {
    // height: ceil($screen__lg * $percent);
    min-height: 0;
    // height: calc((100vw - #{$size__sidebar}) * #{$percent});
  }

  @include respondTo('xl') {
    height: calc((100vw - #{$size__sidebar}) * #{$percent});
    // height: ceil($screen__xl * $percent);
  }

  @include respondTo('xxl') {
    // height: ceil($screen__xxl * $percent);
  }
}

@mixin proportionVideoHeight($height: rem(320px), $height--mobile: rem(400px)) {
  
  $screen__md:  (rem($screen--md) - $size__sidebar);
  $screen__lg:  (rem($screen--lg) - $size__sidebar);
  $screen__xl:  (rem($screen--xl) - $size__sidebar);
  $screen__xxl: (rem($screen--xxl) - $size__sidebar);

  $percent: $height / $screen__xl;
  
  height: calc((100vw) * #{$percent});

  @include respondTo('sm') {
    height: $height;
  }

  @include respondTo('xl') {
    height: calc((100vw - #{$size__sidebar}) * #{$percent});
  }

}