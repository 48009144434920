.nav {
  display: block;
  text-transform: uppercase;
  min-width: rem(130px);

  &__page-tools {
    display: none;
  }

  &__button {
    cursor: pointer;

    &--search {
      & .icon {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
  
  &__list {
    &--center {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__item {
    display: block;
    font-size: $fontsize__6;

    & + &:not(.nav__item--inline) {
      margin-top: $space * 1.25;
    }

    &--inline {
      display: inline-block;
    }

    &--primary {
      font-size: $fontsize__3;
    }

    &--secondary {
      font-size: $fontsize__4;

      &-first {
        margin-top: $space;
      }
    }

    &--sm {
      font-size: $fontsize__6;
    }

    &--soc {
      font-size: $fontsize__3--lg;
    }

    &--lastoflist {
      position: relative;
      padding-bottom: $space__md;
      margin-bottom: $space__md;


      &:after {
        content: "";
        background-color: $color__gray--6;
        height: 1px;
        width: 35%;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }

    &--spaced {
      margin-left: $space__sm;
      margin-right: $space__sm;
    }
  }

  &__search {
    display: none;
  }
  
  &__link {
    @include interactiveColor($color__black, $color__gray--5);
    &:hover {
      text-decoration: none;
    }
    &--lt {
      @include interactiveColor($color__white, $color__second);
    }
    &--prime {
      @include interactiveColor($color__prime, $color__prime--dk);
    }

    .nav__item--soc  & {
      padding: 0 $space__sm;
    }
  }

  &__cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 $space;
  }

  &__col {
    flex: 0 0 auto;
    width: 50%;
    margin-bottom: $space__md;
  }

  &__flexcol {
    width: 100%;
    columns: 2;
    margin-bottom: $space__md;
  }

  @include respondTo('md') {
    min-width: inherit;

    &__search {
      display: block;
    }

    &__page-tools {
      display: block;
    }
    
    &__cols {
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: 0;
    }
  
    &__col {
      width: auto;
      padding: 0 $space;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    &__flexcol {
      columns: 4;
    }

    &__list {
      &--center {
        flex-flow: row nowrap;
        justify-content: center;
      }
    }

    &__item {
      font-size: $fontsize;

      & + &:not(.nav__item--inline) {
        margin-top: $space;
      }
      
      &--sm {
        font-size: $fontsize__6;
      }

      &--primary {
        font-size: $fontsize__4;
      }

      &--secondary {
        font-size: $fontsize__6;
      }
  
      &--soc {
        font-size: $fontsize__3--lg;
      }

      &--lastoflist-page-tools {
        position: relative;
        padding-bottom: $space__md;
        margin-bottom: $space__md;
  
  
        &:after {
          content: "";
          background-color: $color__gray--6;
          height: 1px;
          width: 35%;
          position: absolute;
          top: 100%;
          left: 0;
        }
      }
    }
  }

  @include respondTo('xxl') {
    &__flexcol {
      columns: 5;
    }
  }
}