.header {
  position: fixed;
  top: $size__header--mobile;
  right: 0;
  z-index: $z__header;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 0;
  background-color: $color__bg--dk-2;
  @include transition(height .8s ease, opacity .8s ease, visibility .8s ease);

  &--active {
    opacity: 1;
    visibility: visible;
    height: calc(100vh - #{$size__header--mobile});
  }

  &__inner {
    padding: $space__md $space;
  }

  &__section {
    position: relative;
    display: block;
    
    &--hidden {
      display: none !important;
    }
  }

  &__search-button {
    display: none;
  }

  @include respondTo('lg') {
    width: calc(100% - #{$size__sidebar--lg});

      &__section {
          &--collapser {
              width: 420px;
          }
      }
  }

  @include respondTo('md') {
    top: 0;
    height: 100vh;
    width: calc(100% - #{$size__sidebar--md});

    &__inner {
      position: relative;
      text-align: center;
      padding: 0;
    }

    &__section {
      position: absolute;
      text-align: left;
      display: inline-block;
      
      &--center {
        display: block;
        width: 70%;
        left: 50%;
        top: 0;
        @include translate(-50%, 0);
        padding-top: 20vh;
      }
      
      &--right {
        position: absolute;
        right: 0;
        top: 0;
      }

      &--collapser {
        width: 26%;
        left: $size__sidebar + rem(400px);
      }
    }

    &__search-button {
      display: block;
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  }

  @include respondTo('xl') {
    width: calc(100% - #{$size__sidebar--xl});

    &__section {
      &--collapser {
        width: 420px;
        left: $size__sidebar + rem(586px);
      }
    }
  }

  @include respondTo('xxl') {
    width: calc(100% - #{$size__sidebar--xxl});
  }
  .header-search {
    background-color: #333;
    width: 100%;    
  }
  .header-search-input {        
    background-color: #333;    
    border: 0;
    color: #fff;
    font-size: 1.125rem;    
    font-weight: 700;
    font-family: Roboto,Arial,Microsoft Yahei,微软雅黑,STXihei,华文细黑,sans-serif;
    height: 57px;
    outline: 0;
    padding-left: 20px;    
    padding-right: 2.5rem;
    line-height: 1.2em;
    width: 100%;

    &::placeholder {
      color: #fff;
      font-size: 1.125rem;    
      font-weight: 700;
      font-family: Roboto,Arial,Microsoft Yahei,微软雅黑,STXihei,华文细黑,sans-serif;
    }
  }
  .search-button-link {
    margin-left: -55px;
    width: 50px;
    height: 57px;
  }
  .magnifying-glass-icon {
    height: 18px;
  }
  .search-button-svg {
    height: 18px;
    color: #a0c0ff;
  }
}