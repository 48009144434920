.spotlight {
  padding-top: $space__component;
  padding-bottom: $space__component;
  background-color: $color__prime;
  color: $color__white;

  @include container__fluid($space__md);

  &__full-width {
    color: $color__white;
  }

  &__inner {
    width: 100%;
  }

  &__section {
    width: 100%;

    &--content {
      min-height: $size__tile;
      width: 100%;
    }

    &--header {
      text-align: center;
    }
    
  }

  &__item {
    @include interactiveColor($color__white, $color__aqua);
    display: block;
    padding: 0 $space;
  }

  &__col {
    width: 100%;
    height: auto;

    &--img {
      // display: none;
      height: $size__tile--sm;
      padding-right: 0;
    }
  }

  &__media {
    position: relative;
    overflow: hidden;
    height: 100%;
    text-align: center;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    position: relative;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  &__title,
  &__subtitle {
    display: inline-block;
  }

  &__title {
    margin-right: .25em;
    color: $color__gray--C;
  }

  &__row {
    margin-bottom: $space;
  }

  &__name {
    font-size: $fontsize__4;
    text-transform: uppercase;
    @include fontWeight(bold);
  }

  &__view-all {
    font-weight: $font__weight--bold;
  }

  &__date {
    @include fontWeight(light);
  }

  @include respondTo('ty') {
    &__item {
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
    }

    &__section {

      &--content {
        min-height: $size__tile--sm;
        width: 100%;
      }
    }
  
    &__col {
      flex: 0 0 auto;
      width: 45%;
      height: auto;
      padding-right: $space;
      padding-top: 1em;
      padding-bottom: 1em;
  
      &--img {
        width: 55%;
        display: block;
        height: 100%;
        padding-right: 0;
      }
    }

    &__image {
      top: auto;
      left: 50%;
      @include translate(-50%, 0);
      height: 100%;
      max-width: none;
      width: auto;
    }
  }

  @include respondTo('md') {
    &--wide {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__title,
    &__subtitle {
      .spotlight--wide & {
        display: block;
      }
    }

    &__inner {
      .spotlight--wide & {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: inherit;
      }
    }

    &__section {
      .spotlight--wide & {
        flex: 1 1 auto;

        &--header {
          width: auto;
          flex: 0 0 auto;
          // max-width: $size__spotlightside--md;
          text-align: left;
          margin-right: $space__sm;
        }

        &--content {
          // max-width: calc(100% - #{$size__spotlightside--md});
          min-height: $size__hero;
        }
      }
    }
  }

  @include respondTo('lg') {
    &__section {
      .spotlight--wide & {
        &--header {
          // max-width: $size__spotlightside;
        }

        &--content {
          max-width: calc(100% - #{$size__spotlightside});
        }
      }
    }
  }

  @include respond-to-print {
    padding: $space;
  }
}