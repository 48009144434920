$space: $size;
$space__xt: calc($size / 8);
$space__ty: calc($size / 4);
$space__sm: calc($size / 2);
$space__md: $size * 2;
$space__lg: $size * 3;
$space__xl: $size * 4.5;
$space__xxl: $size * 6;

$space__sidebar: $space;

$space__inner: $space__md;

$space__paragraph: $space;

$space__component: $space__md;

$space__tile: $space__md;

$space__tile--mobile: $space;

$space__section: $space__lg;

$space__pagetop: $space__xl;

$space__pagetop--mobile: $space__sm;