.careers-hero {
    position: relative;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        
        &-video {
            object-fit: cover;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        min-height: 30vh;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            height: 120vh;
        }
    }

    &__heading {
        color: $color__white;
        text-align: center;
        padding: $space__xxl 0;

        &-top {
            font-size: $fontsize__h1;
            font-weight: $font__weight--bold;
        }

        &-title {

            &--blue {
                color: $color__second;
            }
        }

        &-subtitle {
            font-size: $fontsize__h3;
            font-weight: $font__weight--bold;
            width: 90%;
            margin: 0 auto;
        }
    }

    &__jump-links {
        // display: none;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        // height: 0;
        transition: height 0.3s ease-in-out;

        &--active {
            overflow: visible;
            height: 100%;
        }
    }

    &__jump-links-container {
        width: 100%;
        background-color: $color__white;
    }

    &__jump-to-section {
        font-size: $fontsize__h4;
        font-weight: $font__weight--bold;
        position: relative;
        text-align: start;
        width: 100%;
        padding: $space;
        .icon {
            font-size: $fontsize__h3;
            right: $space;
        }
    }

    &__link {
        font-size: $fontsize;
        line-height: $fontsize__3;
        font-weight: $font__weight--bold;
        color: $color__gray--6;
        display: inline-block;
        padding: $space__xt 0 $space;
        // margin-right: $space__lg;
        margin-left: $space;
        border-bottom: 1px solid $color__gray--3;
        text-align: start;
        width: 100%;


        &:hover {
            color: $color__second;
        }
    }

    .component__listitem {
        margin-right: $space__md;
    }

    @include respondTo('md') {
        // margin: -8rem -2rem 0;
        &__heading {
            padding: 0;
            width: 100%;

            &-top {
                font-size: 5rem;
            }
        }

        &__content {
            min-height: 120vh;
        }

        &__jump-links {
            display: flex;
            background-color: none;
            border-top: 2px solid $color__white;
            border-bottom: 2px solid $color__white;
            margin-top: $space;
            height: auto;
            overflow: visible;
        }

        &__jump-links-container {
            width: auto;
            background-color: transparent;
        }

        &__link {
            color: $color__white;
            text-align: center;
            width: auto;
            border-bottom: 0;
            padding-bottom: 0;
            margin-right: $space__lg;
            margin-left: 0;
        }

        &__jump-to-section {
            display: none;
        }
        // copied accordion stuff but need to override in desktop
        .accordion {
            border-top: 0;

            &__item {
                border-bottom: 0;
            }

            &__content {
                height: auto !important;
            }
        }

        .component__listitem {
            margin-top: $space__sm;
            margin-right: 0;

            &:last-of-type {
                .careers-hero__link {
                    margin-right: 0;
                }
            }
        }
    }
}
